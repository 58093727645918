import { Button } from '~/shared/ui/Buttons'
import { Modal, useModal } from '~/shared/ui/Modal'
import { myPalette } from '~/shared/ui/theme'
import { Circle, Degree, Img, ItemsList, ItemWrapper, Title } from './styled'

export function LegendModal() {
  const { isModalShow, openModal, closeModal } = useModal()

  return (
    <>
      <Button
        variant='textBlue'
        uppercase
        onClick={openModal}
        contentProps={{ style: { padding: 0 } }}
      >
        Легенда
      </Button>

      <Modal
        isShow={isModalShow}
        onCancel={closeModal}
        reverseButton
        cancel='Закрыть'
      >
        <Title>Легенда</Title>
        <ItemsList>
          <ItemWrapper>
            <Degree>Л</Degree> - Легкое
          </ItemWrapper>
          {/*<ItemWrapper>*/}
          {/*  <Degree>С</Degree> - Среднее*/}
          {/*</ItemWrapper>*/}
          <ItemWrapper>
            <Degree>Т</Degree> - Тяжелое
          </ItemWrapper>

          <ItemWrapper>
            <Img src='/images/marker.svg' alt='marker' />- выявленное в данном
            осмотре
          </ItemWrapper>
          <ItemWrapper>
            <Circle $background={myPalette.orange['100']} />- выявленное в
            предыдущих осмотрах
          </ItemWrapper>
          <ItemWrapper>
            <Circle $background={myPalette.limeGreen.main} />- отремонтированное
            повреждение
          </ItemWrapper>
        </ItemsList>
      </Modal>
    </>
  )
}
