import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
`

export const Description = styled.div`
  font-size: 12px;
  margin-bottom: 16px;
  color: ${myPalette.dimGray.main};
`

export const CheckBoxListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
