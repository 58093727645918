import { ReactNode } from 'react'
import { Logout } from '~/widgets/Logout'
import { ButtonBack } from '~/shared/ui/ButtonBack'
import { Search } from '../Search'
import { Title, HeaderWrapper, Wrapper } from './styled'

type HeaderProps = {
  title?: string
  canSearch?: boolean
  canBack?: boolean
  backFn?: () => void
  backTitle?: string
  canLogout?: boolean
  extraBlock?: ReactNode
}

export function Header({
  canSearch,
  canBack,
  title,
  backFn,
  backTitle,
  canLogout,
  extraBlock = null,
}: HeaderProps) {
  return (
    <HeaderWrapper>
      <Wrapper>
        {canSearch && <Search />}

        {canBack && <ButtonBack backFn={backFn} title={backTitle} />}

        {title && <Title>{title}</Title>}
      </Wrapper>
      {canLogout && <Logout />}

      {extraBlock}
    </HeaderWrapper>
  )
}
