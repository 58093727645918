import { useMemo } from 'react'
import { MarkerComponentProps } from 'react-image-marker'
import { myPalette } from '~/shared/ui/theme'
import { Wrapper } from './styled'

type MarkerProps = MarkerComponentProps & {
  level?: number
  isSuccess?: boolean
  isOld?: boolean
}

export function Marker({ level, isSuccess, isOld }: MarkerProps) {
  const background = useMemo(() => {
    if (isSuccess) return myPalette.limeGreen.main
    if (isOld) return myPalette.orange['100']

    return null
  }, [isOld, isSuccess])

  const dataTestId = useMemo(() => {
    if (isSuccess) return 'success'
    if (isOld) return 'old'
    return 'new'
  }, [isOld, isSuccess])

  return (
    <Wrapper $background={background} data-testid={`marker-${dataTestId}`}>
      {level}
    </Wrapper>
  )
}
