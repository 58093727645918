import { maskitoTransform } from '@maskito/core'
import { maskitoNumberOptionsGenerator } from '@maskito/kit'
import { useMaskito } from '@maskito/react'
import { ComponentProps } from 'react'
import { Controller } from 'react-hook-form'
import { isNumber } from '~/shared/lib/utils'
import { IonInput } from '../styled'

type NumberParams = {
  min?: number
  max?: number
  precision?: number
  decimalSeparator?: string
  decimalPseudoSeparators?: string[]
  decimalZeroPadding?: boolean
  thousandSeparator?: string
  prefix?: string
  postfix?: string
  minusSign?: string
}

type NumericInputProps = {
  name: string
  numberParams?: NumberParams
} & Omit<ComponentProps<typeof IonInput>, '$invalid'>

export function NumericInput({
  label,
  name,
  type,
  numberParams,
  children,
  ...inputProps
}: NumericInputProps) {
  const numberOptions = maskitoNumberOptionsGenerator({
    thousandSeparator: ' ',
    min: 0,
    ...numberParams,
  })

  const numberRef = useMaskito({ options: numberOptions })

  return (
    <Controller
      name={name}
      defaultValue=''
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => {
        return (
          <IonInput
            ref={numberRef}
            onIonInput={(e) => {
              onChange(e.detail.value || '')
            }}
            value={maskitoTransform(
              isNumber(value) ? String(value) : value,
              numberOptions,
            )}
            name={name}
            label={label}
            type={type}
            errorText={error?.message || 'Обязательное поле'}
            $invalid={invalid}
            {...inputProps}
            labelPlacement='floating'
            inputMode='numeric'
          >
            {children}
          </IonInput>
        )
      }}
    />
  )
}
