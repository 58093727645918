import { LoginForm } from '~/features/auth/login'
import { Wrapper, Title } from './styled'

export function AuthForms() {
  return (
    <Wrapper>
      <Title>Авторизация</Title>

      <LoginForm />
    </Wrapper>
  )
}
