import { Components } from '@ionic/core'
import { HTMLAttributes, ReactNode, useState } from 'react'
import { ContentWrapper, IonModal } from './styled'

type DrawerProps = {
  children: ReactNode
  isShow: boolean
  onCancel?: () => void
  contentProps?: HTMLAttributes<HTMLDivElement>
} & Partial<Components.IonModal>

export function DrawerComponent({
  isShow,
  children,
  onCancel,
  contentProps,
  ...props
}: DrawerProps) {
  const [isDismiss, setIsDismiss] = useState(true)

  return (
    <IonModal
      isOpen={isShow}
      initialBreakpoint={1}
      canDismiss={isDismiss}
      breakpoints={[0, 1]}
      keepContentsMounted={false}
      onWillDismiss={onCancel}
      className='ion-margin-top'
      {...props}
    >
      <ContentWrapper
        onTouchStart={() => setIsDismiss(false)}
        onTouchEnd={() => setIsDismiss(true)}
        {...contentProps}
      >
        {children}
      </ContentWrapper>
    </IonModal>
  )
}
