import type { ComponentProps } from 'react'
import { Controller } from 'react-hook-form'
import { IonTextarea } from './styled'

type TextareaInputProps = { name: string } & Omit<
  ComponentProps<typeof IonTextarea>,
  '$invalid'
>

export function TextareaInput({
  label,
  name,
  children,
  ...inputProps
}: TextareaInputProps) {
  return (
    <Controller
      name={name}
      defaultValue=''
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <IonTextarea
          onIonInput={(e) => {
            onChange(e.detail.value || '')
          }}
          value={value}
          name={name}
          label={label}
          errorText={error?.message || 'Обязательное поле'}
          $invalid={invalid}
          {...inputProps}
        >
          {children}
        </IonTextarea>
      )}
    />
  )
}
