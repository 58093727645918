import { useStore, useStoreMap } from 'effector-react'
import { useMemo, useState } from 'react'
import { inspectionFormModel } from '~/entities/Inspection'
import { InspectionPhotoTypeEnum } from '~/shared/config/enums'
import { ButtonGroup } from '~/shared/ui/Buttons'
import { ImageSliderModal } from '~/shared/ui/ImageSliderModal'
import { useModal } from '~/shared/ui/Modal'
import { Title, Wrapper } from './styled'

enum TypePhoto {
  initial = 'initial',
  rental = 'rental',
  confiscation = 'confiscation',
}

type SelectedCarPhotos = keyof typeof TypePhoto

export function CarPhotos() {
  const { isModalShow, openModal, closeModal } = useModal()

  const [selectedCarPhotos, setSelectedCarPhotos] =
    useState<SelectedCarPhotos>()

  const carInitialPhotos = useStoreMap(
    inspectionFormModel.$lastInitialInspection,
    (inspection) =>
      inspection
        ?.getPhotos()
        .filter((photo) => photo.type === InspectionPhotoTypeEnum.CAR),
  )
  const carInitialPhotosPending = useStore(
    inspectionFormModel.requestLastInitialInspectionFx.pending,
  )

  const carRentalPhotos = useStoreMap(
    inspectionFormModel.$lastRentalInspection,
    (inspection) =>
      inspection
        ?.getPhotos()
        .filter((photo) => photo.type === InspectionPhotoTypeEnum.CAR),
  )
  const carRentalPhotosPending = useStore(
    inspectionFormModel.requestLastRentalInspectionFx.pending,
  )

  const carConfiscationPhotos = useStoreMap(
    inspectionFormModel.$lastConfiscationInspection,
    (inspection) =>
      inspection
        ?.getPhotos()
        .filter((photo) => photo.type === InspectionPhotoTypeEnum.CAR),
  )
  const carConfiscationPhotosPending = useStore(
    inspectionFormModel.requestLastConfiscationInspectionFx.pending,
  )

  const handleSelectedCarPhotosChange = (type: SelectedCarPhotos) => {
    setSelectedCarPhotos(type)
    openModal()
  }

  const carPhotos = useMemo(() => {
    if (!selectedCarPhotos) return

    if (selectedCarPhotos === TypePhoto.initial) return carInitialPhotos
    if (selectedCarPhotos === TypePhoto.rental) return carRentalPhotos
    if (selectedCarPhotos === TypePhoto.confiscation)
      return carConfiscationPhotos
  }, [
    carConfiscationPhotos,
    carInitialPhotos,
    carRentalPhotos,
    selectedCarPhotos,
  ])

  const emptyTitle = useMemo(() => {
    if (selectedCarPhotos === TypePhoto.initial) return 'первичного осмотра'
    if (selectedCarPhotos === TypePhoto.rental) return 'выдачи'
    if (selectedCarPhotos === TypePhoto.confiscation) return 'изъятия'
  }, [selectedCarPhotos])

  const buttonGroupConfig = useMemo(
    () => [
      {
        title: 'Первичный',
        loading: carInitialPhotosPending,
        value: TypePhoto.initial,
      },
      {
        title: 'Выдача',
        loading: carRentalPhotosPending,
        value: TypePhoto.rental,
      },
      {
        title: 'Изъятие',
        loading: carConfiscationPhotosPending,
        value: TypePhoto.confiscation,
      },
    ],
    [
      carConfiscationPhotosPending,
      carInitialPhotosPending,
      carRentalPhotosPending,
    ],
  )

  return (
    <Wrapper>
      <Title>Круговые фото автомобиля</Title>
      <div key={selectedCarPhotos}>
        <ButtonGroup<SelectedCarPhotos>
          config={buttonGroupConfig}
          onClick={handleSelectedCarPhotosChange}
        />
      </div>

      <ImageSliderModal
        photos={carPhotos}
        isShow={isModalShow}
        onCancel={closeModal}
        title='Круговые фото автомобиля'
        empty={`Фото ${emptyTitle} отсутствуют`}
      />
    </Wrapper>
  )
}
