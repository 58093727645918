import { styled } from 'styled-components'
import { HEADER_HEIGHT, myPalette } from '~/shared/ui/theme'

export const HeaderWrapper = styled.div`
  height: ${HEADER_HEIGHT};
  padding: 0 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export const Title = styled.div`
  font-size: 18px;
  line-height: 18px;
  color: ${myPalette.primaryLight.main};
`
