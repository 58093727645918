import { trash, brush } from 'ionicons/icons'
import { ThumbType } from '~/shared/api'
import { IconButton } from '~/shared/ui/Buttons'
import { Img } from '~/shared/ui/Img'
import { myPalette } from '~/shared/ui/theme'
import { FilesContainer, FileWrap, ThumbActions } from './styled'

type UploadFilesProps = {
  previews?: ThumbType[]
  gridTemplateColumns?: string
  onDelete?: (uuid: string) => void
  onChange?: (image: ThumbType) => void
}

export function PreviewsList({
  previews,
  gridTemplateColumns,
  onDelete,
  onChange,
}: UploadFilesProps) {
  if (!previews?.length) return null

  return (
    <FilesContainer gridTemplateColumns={gridTemplateColumns}>
      {previews.map((file) => (
        <FileWrap key={file.uuid}>
          <ThumbActions>
            {onDelete && (
              <IconButton
                onClick={() => onDelete(file.uuid)}
                variant='fillWhite'
                aria-label='Удалить'
                data-testid='delete-image'
                icon={trash}
                style={{ padding: '6px' }}
                iconProps={{
                  style: {
                    color: myPalette.orange.main,
                  },
                }}
              />
            )}
            {onChange && (
              <IconButton
                onClick={() => onChange(file)}
                variant='fillWhite'
                aria-label='Изменить'
                icon={brush}
                style={{ padding: '6px' }}
                iconProps={{
                  style: {
                    color: myPalette.primary.main,
                  },
                }}
              />
            )}
          </ThumbActions>
          <Img url={file.originalUrlDownload} />
        </FileWrap>
      ))}
    </FilesContainer>
  )
}
