import { IonModal as IonicIonModal } from '@ionic/react'
import styled from 'styled-components'
import { BODY_MAX_WIDTH, myPalette } from '../theme'

export const IonModal = styled(IonicIonModal)<{
  isOpen: boolean
}>(
  ({ isOpen }) => `
  --max-width: ${BODY_MAX_WIDTH};
  --border-radius: 4px;
  --height: auto;
  --margin-top: 0;
  margin-top: 0;

  &::part(backdrop) {
    background: ${isOpen ? 'rgba(0, 0, 0, 0.87)' : 'transparent'};
  }

  &::part(handle) {
    width: 110px;
    background: ${myPalette.dark.drawerTopLine};
    top: 12px;
    height: 4px;

    &:before {
      width: 110px;
      height: 46px;
    }
  }
`,
)

export const ContentWrapper = styled.div`
  padding: 28px 16px 36px;
  background: ${myPalette.aliceBlue.main};
  max-height: 90vh;
  overflow-y: auto;
  overscroll-behavior: none;
  --webkit-overscroll-behavior: none;
`
