import { IonSkeletonText } from '@ionic/react'
import { styled } from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled.div`
  padding: 16px;
  background: ${myPalette.white.main};
  border-radius: 16px;
  box-shadow: 0 0 5px 0 rgba(0, 21, 70, 0.1);
  margin-bottom: 30px;
`

export const DriverFullName = styled.div`
  font-size: 16px;
`

export const EmptyRentalContract = styled.div`
  font-size: 16px;
  color: ${myPalette.red.main};
`

export const Skeleton = styled(IonSkeletonText)`
  width: 100%;
  height: 95px;
  border-radius: 16px;
  margin-bottom: 30px;
`

export const CarPlateNumber = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
`
