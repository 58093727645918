import { useGate, useStore } from 'effector-react'
import { MutableRefObject, useRef, useState } from 'react'
import { inspectionFormModel } from '~/entities/Inspection'
import { ThumbType } from '~/shared/api'
import {
  InspectionReasonCodeEnum,
  InspectionStepsEnum,
} from '~/shared/config/enums'
import { formatForApi } from '~/shared/lib/date'
import { getElementBlob } from '~/shared/lib/saveElementImage'
import { timerModel } from '~/shared/ui/Timer'
import { CarBodyInspectionStep } from './CarBodyInspectionStep'
import { CarCabinInspectionStep } from './CarCabinInspectionStep'
import { CarTrunkInspectionStep } from './CarTrunkInspectionStep'
import { CarUnderHoodInspectionStep } from './CarUnderHoodInspectionStep'
import { DocumentsStep } from './DocumentsStep'
import {
  mapWheelsToApi,
  mapCarCabinInspectionFormValuesToDataApi,
  mapDefectsToApi,
  mapDocumentsFormToDataApi,
  mapEquipmentsToApi,
  mapPhotosToApi,
  mapSpecificationFormValuesToDataApi,
  mapServicesToApi,
} from './mappers'
import {
  inspectionCreate,
  inspectionCreateFx,
  saveDamagesMapScreenFx,
} from './model'
import { SignatureStep } from './SignatureStep'
import { SpecificationsStep } from './SpecificationsStep'
import { $currentStep } from './stepModel'
import { DamagesMap } from './ui/DamagesMap'
import { useInspectionForm } from './useInspectionForm'

type InspectionProps = {
  carId: UniqueId
}

export function Inspection({ carId }: InspectionProps) {
  useGate(inspectionFormModel.InspectionFormGate, { carId })

  const startedAt = useStore(inspectionFormModel.$createdAt)
  const refDamagesMap = useRef<HTMLDivElement>()
  const [isScreenCreating, toggleScreenCreating] = useState(false)
  const isInspectionCreatePending = useStore(inspectionCreateFx.pending)
  const saveDamagesMapScreenPending = useStore(saveDamagesMapScreenFx.pending)
  const currentStep = useStore($currentStep)
  const isSubmit =
    isInspectionCreatePending || saveDamagesMapScreenPending || isScreenCreating

  const {
    specificationsStepForm,
    carBodyInspectionStepForm,
    carCabinInspectionStepForm,
    carTrunkInspectionStepForm,
    carUnderHoodInspectionStepForm,
    documentsStepForm,
    defectsCarBody,
    setDefectsCarBody,
    defectsCarInterior,
    setDefectsCarInterior,
    defectsCarEnvironment,
    setDefectsCarEnvironment,
  } = useInspectionForm()

  const reasonCode = specificationsStepForm.watch('reasonOption')?.code
  const withDriverParticipation = specificationsStepForm.watch(
    'withDriverParticipation',
  )

  const needSign =
    Boolean(withDriverParticipation) &&
    reasonCode !== InspectionReasonCodeEnum.INITIAL &&
    reasonCode !== InspectionReasonCodeEnum.CONFISCATION

  const canDefectRepair =
    reasonCode === InspectionReasonCodeEnum.RETURN_FROM_REPAIR

  const handleScreenDamagesMapCreate = async () => {
    try {
      toggleScreenCreating(true)

      const blob = await getElementBlob(refDamagesMap.current as HTMLElement)
      return await saveDamagesMapScreenFx({ screen: blob as Blob, carId })
    } finally {
      toggleScreenCreating(false)
    }
  }

  const handleOnSuccess = async (sign?: ThumbType) => {
    const screen = await handleScreenDamagesMapCreate()
    await handleInspectionCreate([sign, screen])
  }

  const handleInspectionCreate = async (
    somePhotos: (ThumbType | undefined)[],
  ) => {
    const duration = await timerModel.getTotalSecondsStorageFx()
    const specificationsStepFormValues = specificationsStepForm.getValues()
    const carCabinInspectionFormValues = carCabinInspectionStepForm.getValues()
    const carBodyInspectionFormValues = carBodyInspectionStepForm.getValues()
    const carUnderHoodInspectionStepFormValues =
      carUnderHoodInspectionStepForm.getValues()
    const carTrunkInspectionFormValues = carTrunkInspectionStepForm.getValues()
    const defects = [
      ...defectsCarBody,
      ...defectsCarInterior,
      ...defectsCarEnvironment,
    ]

    inspectionCreate({
      carId,
      duration,
      startedAt: formatForApi(startedAt as Date),
      finishedAt: formatForApi(new Date()),
      ...mapSpecificationFormValuesToDataApi(specificationsStepFormValues),
      ...mapCarCabinInspectionFormValuesToDataApi(carCabinInspectionFormValues),
      ...mapDocumentsFormToDataApi(documentsStepForm.getValues()),
      services: mapServicesToApi(carUnderHoodInspectionStepForm.getValues()),
      photos: mapPhotosToApi(
        {
          ...specificationsStepFormValues,
          ...carCabinInspectionFormValues,
          ...carUnderHoodInspectionStepFormValues,
        },
        somePhotos,
      ),
      equipments: mapEquipmentsToApi({
        ...carBodyInspectionFormValues,
        ...carCabinInspectionFormValues,
        ...carTrunkInspectionFormValues,
      }),
      wheelsStates: mapWheelsToApi(carBodyInspectionFormValues),
      damages: mapDefectsToApi(defects),
      repairDamages: defects
        .filter(({ isRepairDamage }) => isRepairDamage)
        .map(({ id }) => id) as string[],
    })
  }

  return (
    <>
      {currentStep === InspectionStepsEnum.SPECIFICATION_STEP && (
        <SpecificationsStep form={specificationsStepForm} />
      )}
      {currentStep === InspectionStepsEnum.CAR_BODY_INSPECTION_STEP && (
        <CarBodyInspectionStep
          form={carBodyInspectionStepForm}
          defects={defectsCarBody}
          onDefectsSet={setDefectsCarBody}
          canDefectRepair={canDefectRepair}
        />
      )}
      {currentStep === InspectionStepsEnum.CAR_CABIN_INSPECTION_STEP && (
        <CarCabinInspectionStep
          form={carCabinInspectionStepForm}
          defectsInterior={defectsCarInterior}
          onDefectsInteriorSet={setDefectsCarInterior}
          defectsEnvironment={defectsCarEnvironment}
          onDefectsEnvironmentSet={setDefectsCarEnvironment}
          canDefectRepair={canDefectRepair}
          reasonCode={reasonCode}
        />
      )}
      {currentStep === InspectionStepsEnum.CAR_TRUNK_INSPECTION_STEP && (
        <CarTrunkInspectionStep form={carTrunkInspectionStepForm} />
      )}
      {currentStep === InspectionStepsEnum.CAR_UNDER_HOOD_INSPECTION_STEP && (
        <CarUnderHoodInspectionStep form={carUnderHoodInspectionStepForm} />
      )}
      {currentStep === InspectionStepsEnum.DOCUMENTS_STEP && (
        <DocumentsStep
          form={documentsStepForm}
          isGoToNexStep={needSign}
          onSuccess={handleOnSuccess}
          isSubmitting={isSubmit}
        />
      )}
      {currentStep === InspectionStepsEnum.SIGNATURE_STEP && needSign && (
        <SignatureStep onSuccess={handleOnSuccess} isSubmitting={isSubmit} />
      )}
      <DamagesMap
        refDamagesMap={refDamagesMap as MutableRefObject<HTMLDivElement>}
        defectsCarBody={defectsCarBody}
        defectsCarEnvironment={defectsCarEnvironment}
        defectsCarInterior={defectsCarInterior}
      />
    </>
  )
}
