import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`

export const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${myPalette.black.main};
  display: flex;
  justify-content: center;
  align-items: center;
`
