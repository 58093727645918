import React, { FunctionComponent, ReactNode, useState } from 'react'
import { logger } from '~/shared/lib/logger'
import { Button } from '~/shared/ui/Buttons'
import { IonModal, Content, ButtonWrapper } from './styled'

type ModalProps = {
  children: ReactNode
  isShow: boolean
  ok?: string
  cancel?: string
  onOk?: () => Promise<void> | void
  onCancel?: () => void
  canBackdropClose?: boolean
  reverseButton?: boolean
  isLoading?: boolean
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShow,
  children,
  ok,
  cancel = 'Отмена',
  onOk,
  onCancel,
  canBackdropClose = true,
  reverseButton,
  isLoading,
}) => {
  const [eventLoading, toggleEventLoading] = useState(false)

  const loading = eventLoading || isLoading
  const handleOnClose = () => {
    if (eventLoading) return
    onCancel?.()
  }

  const handleOnOk = async () => {
    try {
      toggleEventLoading(true)
      await onOk?.()
    } catch (e) {
      logger.error('Modal send error:', e)
    } finally {
      toggleEventLoading(false)
    }
  }

  return (
    <IonModal
      isOpen={isShow}
      onWillDismiss={handleOnClose}
      backdropDismiss={canBackdropClose && !loading}
      data-testid='modal'
      keepContentsMounted={false}
    >
      <Content>{children}</Content>

      <ButtonWrapper $reverse={reverseButton}>
        {onCancel && (
          <Button
            disabled={loading}
            onClick={handleOnClose}
            variant={reverseButton ? 'primaryOutline' : 'crimsonOutline'}
            data-testid='modal-cancel-button'
          >
            {cancel}
          </Button>
        )}
        {onOk && (
          <Button
            loading={loading}
            onClick={handleOnOk}
            variant={reverseButton ? 'crimsonOutline' : 'primaryOutline'}
            data-testid='modal-ok-button'
          >
            {ok}
          </Button>
        )}
      </ButtonWrapper>
    </IonModal>
  )
}
