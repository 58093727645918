import { IonButton as IonicIonButton, IonIcon } from '@ionic/react'
import React, { forwardRef } from 'react'
import type { ComponentProps } from 'react'
import { IonButton } from './styled'

export type Variant = 'outline' | 'fillWhite' | 'blue'

type ButtonProps = ComponentProps<typeof IonicIonButton> & {
  variant?: Variant
  loading?: boolean
  icon: string
  iconProps?: Omit<ComponentProps<typeof IonIcon>, 'slot' | 'icon'>
}

export const IconButton = forwardRef<HTMLIonButtonElement, ButtonProps>(
  (
    { variant = 'outline', loading, disabled, icon, iconProps, ...props },
    ref,
  ) => {
    return (
      <IonButton
        fill='clear'
        {...props}
        $variant={variant}
        ref={ref}
        disabled={disabled || loading}
      >
        <IonIcon {...iconProps} slot='icon-only' icon={icon} />
      </IonButton>
    )
  },
)

IconButton.displayName = 'IconButton'
