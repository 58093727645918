import styled from 'styled-components'
import { HEADER_HEIGHT, myPalette } from '~/shared/ui/theme'

export const WrapperHeader = styled.div`
  height: ${HEADER_HEIGHT};
`

export const WrapperFooter = styled.div`
  display: flex;
`

export const Title = styled.div`
  margin-top: 16px;
  font-size: 20px;
  font-weight: 600;
  color: ${myPalette.aliceBlue.main};
`

export const Description = styled.div`
  font-size: 16px;
  color: ${myPalette.aliceBlue.main};
`
