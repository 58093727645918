import {
  IonSegment as IonicIonSegment,
  IonSegmentButton as IonicIonSegmentButton,
} from '@ionic/react'
import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const IonSegment = styled(IonicIonSegment)`
  border: 1px solid ${myPalette.black.main};
  border-radius: 6px;
  height: 47px;
  background: transparent;
  text-transform: uppercase;
`

export const IonSegmentButton = styled(IonicIonSegmentButton)`
  &::part(indicator-background) {
    border-radius: 4px;
  }
`
