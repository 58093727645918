import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100px;
  border-radius: 16px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 5px 0 rgba(0, 21, 70, 0.1);
  margin-bottom: 8px;
`

export const ContentWrapper = styled.div`
  padding: 16px 24px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
`
