import { IonIcon } from '@ionic/react'
import { checkmarkOutline, removeOutline, addOutline } from 'ionicons/icons'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Button } from '~/shared/ui/Buttons'
import { Modal, useModal } from '~/shared/ui/Modal'
import { myPalette } from '~/shared/ui/theme'
import { Amount, Description, IconButton, InputWrapper, Title } from './styled'

type ModalButtonRangePros = {
  title: string
  buttonTitle: string
  description: string
  step: number
  suffix?: string
  name: string
}

export function ModalButtonRange({
  title,
  buttonTitle,
  description,
  suffix,
  step,
  name,
}: ModalButtonRangePros) {
  const { isModalShow, openModal, closeModal } = useModal()
  const { setValue, getValues } = useFormContext()

  const getDefaultValueForm = () => getValues(name) || 0

  const [amount, setAmount] = useState<number>(getDefaultValueForm())
  const [isAmount, toggleIsAmount] = useState<boolean>(Boolean(amount))

  const minusAmount = () => {
    setAmount((prevAmount) => {
      const value = prevAmount - step
      return value < 0 ? 0 : value
    })
  }

  const plusAmount = () => {
    setAmount((prevAmount) => prevAmount + step)
  }

  const handleOk = () => {
    closeModal()
    setValue(name, amount)
    toggleIsAmount(Boolean(amount))
  }

  const handleCancel = () => {
    closeModal()
    setAmount(getDefaultValueForm())
  }

  return (
    <>
      <Button
        onClick={openModal}
        variant='whiteBorder'
        uppercase
        endDecorator={
          isAmount && (
            <IonIcon
              icon={checkmarkOutline}
              style={{
                color: myPalette.green.main,
                fontSize: '16px',
                '--ionicon-stroke-width': '60px',
              }}
            />
          )
        }
      >
        {buttonTitle}
      </Button>

      <Modal
        isShow={isModalShow}
        onCancel={handleCancel}
        onOk={handleOk}
        ok='Добавить'
      >
        <Title>{title}</Title>
        <Description>{description}</Description>

        <InputWrapper>
          <IconButton
            variant='blue'
            disabled={!amount}
            onClick={minusAmount}
            icon={removeOutline}
            iconProps={{ style: { color: myPalette.white.main } }}
          />

          <Amount>
            {amount}
            {suffix}
          </Amount>

          <IconButton
            variant='blue'
            onClick={plusAmount}
            iconProps={{ style: { color: myPalette.white.main } }}
            icon={addOutline}
          />
        </InputWrapper>
      </Modal>
    </>
  )
}
