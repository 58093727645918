import { IonTextarea as IonicIonTextarea } from '@ionic/react'
import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const IonTextarea = styled(IonicIonTextarea)<{ $invalid: boolean }>`
  background: ${myPalette.white.main};
  --padding-end: 10px;
  --padding-start: 10px;
  --border-color: red;
  border-radius: 6px;
  border: 1px solid;

  .textarea-bottom {
    display: none;
  }

  .input-bottom {
    .helper-text {
      display: ${({ $invalid }) => ($invalid ? 'none' : 'block')} !important;
    }
    .error-text {
      display: ${({ $invalid }) => ($invalid ? 'block' : 'none')} !important;
    }
  }
`
