import { useStore } from 'effector-react'
import { useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  $inspectionReasonOptions,
  $lastInspection,
  $savingPhotosCount,
  requestInspectionReasonOptionsFx,
  savePhoto,
} from '~/entities/Inspection/model'
import { InspectionFetchOption, RepairOrderAttributes } from '~/shared/api'
import { incidentResponsibleEnumOptions } from '~/shared/config/constants'
import {
  IncidentResponsibleEnum,
  InspectionPhotoTypeEnum,
  InspectionReasonCodeEnum,
} from '~/shared/config/enums'
import { formatDateForUI } from '~/shared/lib/date'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { sanitizeNumber } from '~/shared/lib/utils'
import { Button } from '~/shared/ui/Buttons'
import {
  DrawerSelect,
  NumericInput,
  Camera,
  FormProvider,
  Switch,
} from '~/shared/ui/form'
import { RadioGroup } from '~/shared/ui/form/RadioGroup'
import { FormHeader } from '../FormHeader'
import { helpersCarPhotosCamera } from './constants'
import {
  CameraCarPhotosDescriptions,
  LatestRepairOrderDescription,
  MileageError,
  ParamsWrapper,
  Wrapper,
} from './styled'
import { SpecificationsFormValues } from './types'

type SpecificationsFormProps = {
  onSuccess: () => void
  form: UseFormReturn<SpecificationsFormValues>
  latestRepairOrder?: RepairOrderAttributes
}

export function SpecificationsForm({
  onSuccess,
  form,
  latestRepairOrder,
}: SpecificationsFormProps) {
  const reasonOptionsLoading = useStore(
    requestInspectionReasonOptionsFx.pending,
  )
  const reasonOptions = useStore($inspectionReasonOptions)

  const savingCarPhotoCount =
    useStore($savingPhotosCount)[InspectionPhotoTypeEnum.CAR]
  const isCarPhotoPending = savingCarPhotoCount > 0

  const savingMileagePhotoCount =
    useStore($savingPhotosCount)[InspectionPhotoTypeEnum.MILEAGE]
  const isMileagePhotoPending = savingMileagePhotoCount > 0

  const lastInspection = useStore($lastInspection)
  const lastInspectionMileage = useMemo(
    () => lastInspection?.getMileage() || 0,
    [lastInspection],
  )

  const [mileageError, setMileageError] = useState<string>()

  const { watch, setValue } = form

  const reasonOptionCode = watch('reasonOption')?.code

  const canIncidentResponsibleChoose =
    reasonOptionCode === InspectionReasonCodeEnum.AFTER_ACCIDENT

  const showWithDriverParticipationParams =
    reasonOptionCode !== InspectionReasonCodeEnum.INITIAL &&
    reasonOptionCode !== InspectionReasonCodeEnum.CONFISCATION

  const showIsPlannedParams =
    reasonOptionCode === InspectionReasonCodeEnum.PLAN ||
    reasonOptionCode === InspectionReasonCodeEnum.REFUND

  const handleMileageCheck = (mileage?: number) => {
    const sanitizeMileage = sanitizeNumber(mileage || 0)
    const lastMillage = lastInspectionMileage.toLocaleString()

    if (!sanitizeMileage) {
      setMileageError('Обязательное поле')
    } else if (!lastInspectionMileage) {
      setMileageError('')
    } else if (lastInspectionMileage > sanitizeMileage) {
      setMileageError(
        `Пробег не может быть меньше чем ${lastMillage} км в предыдущем осмотре!`,
      )
    } else if (lastInspectionMileage + 50000 < sanitizeMileage) {
      setMileageError(
        `Пробег не может быть больше чем на 50 тыс км чем пробег ${lastMillage} км с последнего осмотра!`,
      )
    } else {
      setMileageError('')
    }
  }

  useEffect(() => {
    // Для автомобилей без осмотров по дефолту INITIAL
    if (!lastInspection && reasonOptions?.length) {
      setValue(
        'reasonOption',
        reasonOptions.find(
          (option) => option.code === InspectionReasonCodeEnum.INITIAL,
        ) as InspectionFetchOption,
      )
    }
    // eslint-disable-next-line
  }, [reasonOptions])

  watch(async (data, { name, type }) => {
    if (type !== 'change') return

    if (name === 'reasonOption') {
      setValue(
        'incidentResponsible',
        data[name]?.code === InspectionReasonCodeEnum.AFTER_ACCIDENT
          ? IncidentResponsibleEnum.NOT_DRIVER
          : undefined,
      )
      setValue('withDriverParticipation', false)
      setValue('isPlanned', false)
    }
    if (name === 'mileage') {
      handleMileageCheck(data[name])
    }
  })

  const mappedParams = useMemo(() => {
    return [
      { name: 'isExteriorClean', label: 'Чистая машина' },
      { name: 'isCarRefueled', label: 'Авто заправлено' },
      showWithDriverParticipationParams
        ? {
            name: 'withDriverParticipation',
            label: 'Водитель участвует в осмотре',
          }
        : null,
      showIsPlannedParams
        ? { name: 'isPlanned', label: 'Осмотр по записи' }
        : null,
    ].filter(Boolean) as { name: string; label: string }[]
  }, [showIsPlannedParams, showWithDriverParticipationParams])

  const handleOnSuccess = () => {
    if (mileageError) {
      snackbarEnqueued({
        message: 'Для перехода на следующий шаг укажите пробег правильно!',
        variant: 'warning',
      })
      return
    }
    onSuccess()
  }

  return (
    <FormProvider form={form} onSuccess={handleOnSuccess}>
      <Wrapper>
        <div>
          <NumericInput label='Введите пробег' name='mileage' />
          {mileageError && <MileageError>{mileageError}</MileageError>}
        </div>

        {latestRepairOrder && (
          <LatestRepairOrderDescription>
            Последнее ТО: {formatDateForUI(latestRepairOrder.createdAt)};{' '}
            {latestRepairOrder?.mileage.toLocaleString()} КМ
          </LatestRepairOrderDescription>
        )}

        <Camera
          buttonTitle='Фото пробега'
          name='mileagePhotos'
          saveFileFn={(file) =>
            savePhoto({
              file,
              type: InspectionPhotoTypeEnum.MILEAGE,
              form,
              fieldName: 'mileagePhotos',
            })
          }
          isLoading={isMileagePhotoPending}
        />

        <DrawerSelect
          label='Причина осмотра'
          name='reasonOption'
          defaultOptions={reasonOptions}
          loading={reasonOptionsLoading}
          disabled={!lastInspection}
          title='Причина осмотра'
          dataTestId='inspection-reason-drawer'
        />

        <div>
          <Camera
            buttonTitle='Добавить фото авто'
            name='carPhotos'
            helpers={helpersCarPhotosCamera}
            saveFileFn={(file) =>
              savePhoto({
                file,
                type: InspectionPhotoTypeEnum.CAR,
                form,
                fieldName: 'carPhotos',
              })
            }
            isLoading={isCarPhotoPending}
          />
          <CameraCarPhotosDescriptions>
            Добавьте минимум 4 фото (со всех сторон)
          </CameraCarPhotosDescriptions>
        </div>

        {canIncidentResponsibleChoose && (
          <>
            <FormHeader title='Виновник происшествия' />
            <ParamsWrapper>
              <RadioGroup
                name='incidentResponsible'
                options={incidentResponsibleEnumOptions}
              />
            </ParamsWrapper>
          </>
        )}

        <ParamsWrapper>
          {mappedParams.map((param) => (
            <Switch key={param.name} label={param.label} name={param.name} />
          ))}
        </ParamsWrapper>

        <Button type='submit' fullWidth uppercase>
          Далее
        </Button>
      </Wrapper>
    </FormProvider>
  )
}
