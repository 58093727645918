import { logOutOutline } from 'ionicons/icons'
import { useCallback } from 'react'
import { viewerModel } from '~/entities/Viewer'
import { IconButton } from '~/shared/ui/Buttons'
import { Modal, useModal } from '~/shared/ui/Modal'
import { myPalette } from '~/shared/ui/theme'

export function Logout() {
  const { isModalShow, openModal, closeModal } = useModal()

  const handleSignOut = useCallback(async () => {
    await viewerModel.logoutFx()
    closeModal()
  }, [closeModal])

  return (
    <>
      <IconButton
        icon={logOutOutline}
        onClick={openModal}
        iconProps={{ style: { color: myPalette.white.main } }}
      />

      <Modal
        isShow={isModalShow}
        onCancel={closeModal}
        onOk={handleSignOut}
        cancel='Нет'
        ok='Да'
        canBackdropClose={false}
        reverseButton
      >
        <div style={{ fontSize: '20px', fontWeight: 500 }}>
          Выйти из учетной записи?
        </div>
      </Modal>
    </>
  )
}
