import { Signature, Header } from '~/entities/Inspection'
import { ThumbType } from '~/shared/api'
import { Title } from './styled'

type SignatureStepProps = {
  isSubmitting: boolean
  onSuccess: (sign: ThumbType) => void
}

export function SignatureStep({ isSubmitting, onSuccess }: SignatureStepProps) {
  return (
    <>
      <Header title='Подпись водителя' />
      <Title>
        Я ознакомлен с результатами осмотра и подтверждаю выявленные повреждения
      </Title>

      <Signature onSuccess={onSuccess} isSubmitting={isSubmitting} />
    </>
  )
}
