import { IonToggle } from '@ionic/react'
import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Toggle = styled(IonToggle)`
  height: 36px;
  --track-background-checked: ${myPalette.blue.cornflowerBlue};
  --handle-background-checked: ${myPalette.prussianBlue.main};
  --handle-width: 24px;
  --handle-height: 24px;
  --handle-max-height: auto;
  contain: none;

  &::part(track) {
    height: 16px;
    width: 44px;
    overflow: visible;
  }
`
