import styled from 'styled-components'

export const Wrapper = styled.div<{ $markerScale?: number }>`
  .image-marker__marker {
    transform: scale(1 / ${({ $markerScale }) => $markerScale});
    transition: transform 0.3s;
  }
`
export const MarkerWrapper = styled.div`
  cursor: pointer;
`
