import styled from 'styled-components'

export const Title = styled.div`
  font-size: 24px;
  line-height: 26px;
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 18px;
`
