import { useStore, useStoreMap } from 'effector-react'
import { inspectionModel } from '~/features/Inspection'
import {
  DriverFullName,
  EmptyRentalContract,
  Wrapper,
  Skeleton,
  CarPlateNumber,
} from './styled'

export function CarData() {
  const isCarLoading = useStore(inspectionModel.requestCarFx.pending)
  const plateNumber = useStoreMap(inspectionModel.$car, (car) =>
    car?.getPlateNumber(),
  )
  const driverFullName = useStoreMap(inspectionModel.$car, (car) =>
    car?.getDriverFullName(),
  )

  const rentalContract = driverFullName ? (
    <DriverFullName>{driverFullName}</DriverFullName>
  ) : (
    <EmptyRentalContract>
      Отсутствует договор аренды с водителем
    </EmptyRentalContract>
  )
  return (
    <>
      {isCarLoading ? (
        <Skeleton />
      ) : (
        <Wrapper>
          <CarPlateNumber>{plateNumber}</CarPlateNumber>
          {rentalContract}
        </Wrapper>
      )}
    </>
  )
}
