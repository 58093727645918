import { IonIcon } from '@ionic/react'
import { useGate } from 'effector-react'
import { timeOutline } from 'ionicons/icons'
import { useEffect, useMemo } from 'react'
import { useStopwatch } from 'react-timer-hook'
import { myPalette } from '~/shared/ui/theme'
import { setTotalSeconds, Gate } from './model'
import { Title, Wrapper } from './styled'

type TimerProps = {
  errorAfterMinutes?: number
}

export function Timer({ errorAfterMinutes }: TimerProps) {
  useGate(Gate)
  const { seconds, minutes, hours, totalSeconds } = useStopwatch({
    autoStart: true,
  })

  const uiHours = hours ? `${hours}:` : ''
  const uiMinutes = minutes < 10 ? `0${minutes}` : minutes
  const uiSeconds = seconds < 10 ? `0${seconds}` : seconds

  const isError = useMemo(() => {
    if (!errorAfterMinutes) return false
    return hours > 0 || minutes > errorAfterMinutes
  }, [errorAfterMinutes, hours, minutes])

  useEffect(() => {
    setTotalSeconds(totalSeconds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSeconds])

  return (
    <Wrapper>
      <IonIcon
        icon={timeOutline}
        style={{
          fontSize: '24px',
          marginRight: '8px',
          color: isError ? myPalette.red.main : myPalette.primaryLight.main,
        }}
      />
      <Title $isError={isError}>
        {uiHours}
        {uiMinutes}:{uiSeconds}
      </Title>
    </Wrapper>
  )
}
