import styled from 'styled-components'
import { IconButton as DefaultIconButton } from '~/shared/ui/Buttons'
import { myPalette } from '~/shared/ui/theme'

export const IconButton = styled(DefaultIconButton)`
  border-radius: 0;
`

export const InputWrapper = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`

export const Amount = styled.div`
  font-size: 24px;
  width: 130px;
  height: 32px;
  text-align: center;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
`

export const Description = styled.div`
  font-size: 12px;
  margin-bottom: 16px;
  color: ${myPalette.dimGray.main};
`
