import styled from 'styled-components'

export const Wrapper = styled.div<{
  $isForeground?: boolean
}>(({ $isForeground }) => ({
  position: 'relative',

  '.ReactFreeSelect__Component svg': {
    zIndex: $isForeground ? 2 : 0,

    rect: {
      width: '4px',
      height: '4px',
      fill: 'red',
      stroke: 'cyan',
      opacity: '0.6',
    },
  },
}))

export const Polyline = styled.polyline`
  opacity: 0.7;
  fill: chartreuse;
  stroke: coral;
  transition: 0.3s;

  &:hover {
    fill: blueviolet;
    stroke: fuchsia;
  }
`

export const PolygonsViewWrapper = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
`
