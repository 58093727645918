import { useStoreMap } from 'effector-react'
import { UseFormReturn } from 'react-hook-form'
import { inspectionModel } from '~/features/Inspection'
import {
  Header,
  SpecificationsForm,
  SpecificationsFormValues,
} from '~/entities/Inspection'
import { goToNextStep } from '../stepModel'
import { CarData } from './ui'

type SpecificationsStepProps = {
  form: UseFormReturn<SpecificationsFormValues>
}

export function SpecificationsStep({ form }: SpecificationsStepProps) {
  const latestRepairOrderMaintenance = useStoreMap(
    inspectionModel.$car,
    (car) => car?.getLatestRepairOrderMaintenance()?.getAttributes(),
  )

  const onSuccess = () => {
    goToNextStep()
  }

  return (
    <>
      <Header title='Общие характеристики' />

      <CarData />

      <SpecificationsForm
        onSuccess={onSuccess}
        form={form}
        latestRepairOrder={latestRepairOrderMaintenance}
      />
    </>
  )
}
