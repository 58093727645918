import type { ComponentProps } from 'react'
import { Controller } from 'react-hook-form'
import { IonInput } from '../styled'

type TextInputProps = { name: string } & Omit<
  ComponentProps<typeof IonInput>,
  '$invalid'
>

export function TextInput({
  label,
  name,
  type,
  children,
  ...inputProps
}: TextInputProps) {
  return (
    <Controller
      name={name}
      defaultValue=''
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <IonInput
          onIonInput={(e) => {
            onChange(e.detail.value || '')
          }}
          value={value}
          name={name}
          label={label}
          type={type}
          errorText={error?.message || 'Обязательное поле'}
          $invalid={invalid}
          {...inputProps}
          labelPlacement='floating'
        >
          {children}
        </IonInput>
      )}
    />
  )
}
