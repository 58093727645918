import { BaseSyntheticEvent, ReactNode } from 'react'
import {
  FormProvider as ReactHookFormProvider,
  UseFormReturn,
} from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types'

type FormProviderProps<TFieldValues extends FieldValues = FieldValues> = {
  children: ReactNode
  form: UseFormReturn<TFieldValues>
  onSuccess?: (values: TFieldValues) => void
}

export function FormProvider<TFieldValues extends FieldValues = FieldValues>({
  children,
  form,
  onSuccess,
}: FormProviderProps<TFieldValues>) {
  const { handleSubmit } = form

  const handleSuccess = (values: TFieldValues, event?: BaseSyntheticEvent) => {
    event?.preventDefault()
    onSuccess?.(values)
  }

  return (
    <ReactHookFormProvider {...form}>
      <form
        noValidate
        style={{ margin: 0, width: '100%' }}
        {...(onSuccess ? { onSubmit: handleSubmit(handleSuccess) } : {})}
      >
        {children}
      </form>
    </ReactHookFormProvider>
  )
}
