import { IonIcon } from '@ionic/react'
import { warningOutline } from 'ionicons/icons'
import { pushFx } from '~/shared/lib/history'
import { Button } from '~/shared/ui/Buttons'
import { myPalette } from '~/shared/ui/theme'
import { Title, Wrapper } from './styled'

type Props = {
  message?: string
  fullScreen?: boolean
}

export function NotFound({ message = 'Страница не найдена' }: Props) {
  return (
    <Wrapper>
      <IonIcon
        icon={warningOutline}
        style={{ color: myPalette.orange.main, fontSize: '60px' }}
      />
      <br />

      <Title>{message}</Title>

      <Button variant='textBlue' onClick={() => pushFx('/')}>
        На главную
      </Button>
    </Wrapper>
  )
}
