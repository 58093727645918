import { useStore } from 'effector-react'
import { UseFormReturn } from 'react-hook-form'
import { inspectionFormModel } from '~/entities/Inspection'
import { Button } from '~/shared/ui/Buttons'
import { Checkbox, FormProvider } from '~/shared/ui/form'
import { Skeleton } from '~/shared/ui/SkeletonList'
import { Wrapper, CheckBoxListWrapper } from './styled'
import { CarTrunkInspectionFormValues } from './types'

type CarTrunkInspectionFormProps = {
  onSuccess: () => void
  form: UseFormReturn<CarTrunkInspectionFormValues>
}

export function CarTrunkInspectionForm({
  onSuccess,
  form,
}: CarTrunkInspectionFormProps) {
  const carEquipmentsTrunkOptions = useStore(
    inspectionFormModel.$carEquipmentsTrunkOptions,
  )
  const isCarEquipmentsPending = useStore(
    inspectionFormModel.requestCarEquipmentsFx.pending,
  )

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Wrapper>
        {isCarEquipmentsPending && <Skeleton animated $height={150} />}

        <CheckBoxListWrapper>
          {!isCarEquipmentsPending &&
            carEquipmentsTrunkOptions.map((param) => (
              <Checkbox
                key={param.id}
                label={param.label}
                name={`equipmentsTrunk.${param.id}`}
              />
            ))}
        </CheckBoxListWrapper>

        <Button
          fullWidth
          type='submit'
          variant='limeFlooded'
          uppercase
          style={{ marginTop: '16px' }}
        >
          Осмотреть под капотом
        </Button>
      </Wrapper>
    </FormProvider>
  )
}
