import styled from 'styled-components'

export const WrapperLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Image = styled.img`
  object-fit: contain;
  width: 100%;
`
