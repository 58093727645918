import { useStore } from 'effector-react'
import { viewerModel } from '~/entities/Viewer'
import { Button } from '~/shared/ui/Buttons'
import { Wrapper, Title } from './styled'

export function AlreadyAuthorized() {
  const isLogoutPending = useStore(viewerModel.logoutFx.pending)

  return (
    <Wrapper>
      <Title>Вы уже авторизованы</Title>
      <Button
        variant='primaryOutline'
        fullWidth
        routerLink='/'
        disabled={isLogoutPending}
      >
        На главную
      </Button>

      <Button
        loading={isLogoutPending}
        fullWidth
        onClick={() => viewerModel.signOut()}
      >
        Выйти
      </Button>
    </Wrapper>
  )
}
