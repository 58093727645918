import { useCallback } from 'react'
import { useQueryParam } from 'use-query-params'
import { Car, CarAttributes } from '~/shared/api'
import { constants } from '~/shared/config'
import {
  ScrollableList,
  FetchFn,
  SCROLLABLE_LIST_META_PAGE,
  Item,
} from '~/shared/ui/ScrollableList'
import { Card } from './ui/Card'

type ListSearchCarProps = {
  onInspectionStart: (carId: string) => void
}

type Row = Item & {
  id: UniqueId
  car: CarAttributes
}

export function ListSearchCar({ onInspectionStart }: ListSearchCarProps) {
  const [searchFilter] = useQueryParam<string>(constants.HEADER_SEARCH_KEY)

  const fetchRows = useCallback<FetchFn<Row>>(
    async (page, pageSize, filters) => {
      let builder = Car.limit(pageSize)

      filters?.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)
      const data = response.getData()

      const items = data.map((car) => {
        return {
          id: car?.getApiId() as string,
          car: car.getAttributes(),
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const { to, currentPage, lastPage } =
        axiosResponse?.data?.meta?.page || SCROLLABLE_LIST_META_PAGE

      return {
        items,
        to,
        currentPage,
        lastPage,
      }
    },
    [],
  )

  const handleMapItems = useCallback(
    (items: Row[]) => {
      return items.map((item) => (
        <Card
          key={item.id}
          onStart={() => onInspectionStart(item.id)}
          car={item.car}
        />
      ))
    },
    [onInspectionStart],
  )

  return (
    <ScrollableList
      fetchFn={fetchRows}
      externalFilter={[{ key: 'plateNumber', value: searchFilter }]}
      empty={`Автомобиль с гос номером ${searchFilter} не найден`}
      mapItems={handleMapItems}
    />
  )
}
