import { UseFormReturn } from 'react-hook-form'
import { documentsCodeEnumOptions } from '~/shared/config/constants'
import { Button } from '~/shared/ui/Buttons'
import { Checkbox, FormProvider } from '~/shared/ui/form'
import { TextareaInput } from '~/shared/ui/form/TextareaInput'
import { FormHeader } from '../FormHeader'
import { CheckBoxListWrapper, Wrapper } from './styled'
import { DocumentsFormValues } from './types'

type DocumentsFormProps = {
  onSuccess: () => void
  form: UseFormReturn<DocumentsFormValues>
  submitTitle: string
  isSubmitting: boolean
}

export function DocumentsForm({
  onSuccess,
  form,
  submitTitle,
  isSubmitting,
}: DocumentsFormProps) {
  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Wrapper>
        <CheckBoxListWrapper>
          {documentsCodeEnumOptions.map((param) => (
            <Checkbox
              key={param.id}
              label={param.label}
              name={`documents.${param.id}`}
            />
          ))}
        </CheckBoxListWrapper>

        <FormHeader title='Комментарий' />

        <TextareaInput name='comment' rows={6} />

        <Button
          fullWidth
          type='submit'
          variant='limeFlooded'
          uppercase
          style={{ marginTop: '16px' }}
          loading={isSubmitting}
        >
          {submitTitle}
        </Button>
      </Wrapper>
    </FormProvider>
  )
}
