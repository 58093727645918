import { IonIcon } from '@ionic/react'
import { addOutline, checkmarkOutline } from 'ionicons/icons'
import { ReactNode } from 'react'
import { Button } from '~/shared/ui/Buttons'
import { myPalette } from '~/shared/ui/theme'
import { MessageError } from './styled'

type ButtonFieldProps = {
  children?: ReactNode
  errorMessage?: string
  onClick?: () => void
  isLoading?: boolean
  isCheck?: boolean
  dataTestId?: string
}

export function ButtonField({
  children,
  errorMessage,
  onClick,
  isLoading,
  isCheck,
  dataTestId,
}: ButtonFieldProps) {
  return (
    <div data-testid={dataTestId}>
      <Button
        variant='whiteBorder'
        fullWidth
        uppercase
        loading={isLoading}
        onClick={onClick}
        endDecorator={
          <IonIcon
            style={{
              fontSize: '16px',
              '--ionicon-stroke-width': '52px',
              color: isCheck ? myPalette.green.main : myPalette.black.main,
            }}
            icon={isCheck ? checkmarkOutline : addOutline}
          />
        }
      >
        {children}
      </Button>

      {errorMessage && (
        <MessageError data-testid='error-message'>{errorMessage}</MessageError>
      )}
    </div>
  )
}
