import { IonIcon, IonPopover } from '@ionic/react'
import { caretDownOutline } from 'ionicons/icons'
import { trash } from 'ionicons/icons'
import { MouseEvent, useRef, useState } from 'react'
import { Button } from '~/shared/ui/Buttons'
import { myPalette } from '~/shared/ui/theme'
import { Timer } from '~/shared/ui/Timer'

type TimerButtonProps = {
  onPopoverReject: () => void
}

export function TimerButton({ onPopoverReject }: TimerButtonProps) {
  const ref = useRef<HTMLIonPopoverElement>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)

  const openPopover = (event: MouseEvent<HTMLIonButtonElement>) => {
    if (ref.current) ref.current.event = event
    setPopoverOpen(true)
  }

  const handleReject = () => {
    setPopoverOpen(false)
    onPopoverReject()
  }

  return (
    <>
      <Button
        onClick={openPopover}
        contentProps={{ style: { padding: '4px' } }}
        variant='text'
        endDecorator={
          <IonIcon
            icon={caretDownOutline}
            style={{ color: myPalette.aliceBlue.main }}
          />
        }
      >
        <Timer errorAfterMinutes={20} />
      </Button>

      <IonPopover
        mode='md'
        ref={ref}
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
        style={{ '--width': '214px', '--max-width': '214px' }}
        side='bottom'
        alignment='start'
      >
        <Button
          variant='text'
          onClick={handleReject}
          startDecorator={<IonIcon icon={trash} />}
        >
          Прервать осмотр
        </Button>
      </IonPopover>
    </>
  )
}
