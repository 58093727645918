import { useStore } from 'effector-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { inspectionFormModel } from '~/entities/Inspection'
import { $savingPhotosCount, savePhoto } from '~/entities/Inspection/model'
import { CarDetailOption } from '~/shared/api'
import { InspectionPhotoTypeEnum } from '~/shared/config/enums'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Button } from '~/shared/ui/Buttons'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { DrawerSelect, Camera, FormProvider } from '~/shared/ui/form'
import { PolygonMarker } from '~/shared/ui/Polygon/types'
import { ActionButtonWrapper, Wrapper } from './styled'
import { DefectDrawerFormValues, Defect } from './types'

type DefectDrawerProps = {
  isShow: boolean
  onClose: () => void
  onSuccess: (formValues: DefectDrawerFormValues) => void
  selectedDefect?: Defect
  newMarker?: PolygonMarker
  carDetailsOptions: CarDetailOption[]
  onDeleteMarker: () => void
}
const defaultValues = {
  carDamageExtent: null,
  carDetail: null,
  photos: [],
}

export function DefectDrawer({
  isShow,
  onClose,
  onSuccess,
  selectedDefect,
  newMarker,
  carDetailsOptions,
  onDeleteMarker,
}: DefectDrawerProps) {
  const savingPhotoCount =
    useStore($savingPhotosCount)[InspectionPhotoTypeEnum.DAMAGE]
  const isPhotoPending = savingPhotoCount > 0

  const isCarDamageExtentsPending = useStore(
    inspectionFormModel.requestCarDamageExtentsFx.pending,
  )
  const isCarDetailsPending = useStore(
    inspectionFormModel.requestCarDetailsFx.pending,
  )

  const carDamageExtentsOptions = useStore(
    inspectionFormModel.$carDamageExtentsOptions,
  )

  const [photosErrorMessage, setPhotosErrorMessage] = useState<string>()

  const form = useForm<DefectDrawerFormValues>()
  const { reset, setValue, watch } = form

  const photos = watch('photos')

  const handleClose = () => {
    reset()
    onClose()
  }

  useEffect(() => {
    if (!newMarker) return
    const foundCarDetails = carDetailsOptions.find(
      (detail) => detail.code === newMarker.code,
    )
    if (!foundCarDetails) return

    setValue('carDetail', foundCarDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMarker])

  useEffect(() => {
    reset(selectedDefect || defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDefect])

  useEffect(() => {
    if (photos?.length) {
      setPhotosErrorMessage(undefined)
    }
  }, [photos])

  const handleSuccess = (values: DefectDrawerFormValues) => {
    if (!photos?.length) {
      setPhotosErrorMessage('Прикрепите фото дефекта')
      snackbarEnqueued({
        message: 'Прикрепите фото дефекта',
        variant: 'warning',
      })
      return
    }
    onSuccess(values)
    handleClose()
  }

  return (
    <DrawerComponent isShow={isShow} onCancel={handleClose}>
      <FormProvider form={form} onSuccess={handleSuccess}>
        <Wrapper>
          <DrawerSelect
            label='Степень повреждения'
            name='carDamageExtent'
            title='Выберите степень повреждения'
            defaultOptions={carDamageExtentsOptions}
            loading={isCarDamageExtentsPending}
            validation={{ required: 'Обязательное поле' }}
            dataTestId='driver-select-carDamageExtent'
          />
          <DrawerSelect
            label='Область повреждения'
            name='carDetail'
            title='Выберите область повреждения'
            defaultOptions={carDetailsOptions}
            loading={isCarDetailsPending}
            validation={{ required: 'Обязательное поле' }}
            dataTestId='driver-select-carDetail'
          />
          <div data-testid='camera-container'>
            <Camera
              buttonTitle='Добавить фото'
              name='photos'
              errorMessage={photosErrorMessage}
              canPaint
              showPreviewList
              saveFileFn={(file) =>
                savePhoto({
                  file,
                  type: InspectionPhotoTypeEnum.DAMAGE,
                  form,
                  fieldName: 'photos',
                })
              }
              isLoading={isPhotoPending}
            />
          </div>

          <br />

          <Button
            fullWidth
            uppercase
            type='submit'
            variant='limeFlooded'
            loading={isPhotoPending}
          >
            Сохранить
          </Button>

          <ActionButtonWrapper>
            {selectedDefect && (
              <Button
                fullWidth
                variant='warningBorder'
                onClick={onDeleteMarker}
              >
                Удалить
              </Button>
            )}
            <Button
              uppercase
              fullWidth
              variant='whiteBorder'
              onClick={handleClose}
            >
              Отмена
            </Button>
          </ActionButtonWrapper>
        </Wrapper>
      </FormProvider>
    </DrawerComponent>
  )
}
