import { IonCheckbox as IonicIonCheckbox } from '@ionic/react'
import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const IonCheckbox = styled(IonicIonCheckbox)`
  --size: 22px;
  --border-width: 3px;
  --border-color: ${myPalette.prussianBlue.main};
  --border-color-checked: ${myPalette.prussianBlue.main};
  --checkbox-background-checked: ${myPalette.prussianBlue.main};
`
