import {
  IonRadioGroup as IonicIonRadioGroup,
  IonRadio as IonicIonRadio,
} from '@ionic/react'
import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const IonRadioGroup = styled(IonicIonRadioGroup)`
  display: flex;
  flex-direction: column;
`

export const IonRadio = styled(IonicIonRadio)`
  height: 36px;
  --border-radius: 50px;
  --inner-border-radius: 50px;

  --color: ${myPalette.prussianBlue.main};
  --color-checked: ${myPalette.prussianBlue.main};

  &::part(container) {
    width: 20px;
    height: 20px;
  }

  &::part(container) {
    border-color: ${myPalette.prussianBlue.main};
  }

  &::part(label) {
    margin-left: 10px;
  }
`
