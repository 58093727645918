import { IonIcon } from '@ionic/react'
import { checkmarkOutline } from 'ionicons/icons'
import { useForm, useFormContext } from 'react-hook-form'
import { Button } from '~/shared/ui/Buttons'
import { Checkbox, FormProvider } from '~/shared/ui/form'
import { Modal, useModal } from '~/shared/ui/Modal'
import { myPalette } from '~/shared/ui/theme'
import { CheckBoxListWrapper, Description, Title } from './styled'

type ModalButtonCheckboxesPros = {
  title: string
  buttonTitle: string
  description: string
  name: string
  options: { id: string; label: string }[]
}

export function ModalButtonCheckboxes({
  title,
  buttonTitle,
  description,
  name,
  options,
}: ModalButtonCheckboxesPros) {
  const { setValue, getValues, watch } = useFormContext()
  const getDefaultValuesForm = () => getValues(name) || {}

  const form = useForm({ defaultValues: getDefaultValuesForm() })
  const { isModalShow, openModal, closeModal } = useModal()

  const values = watch(name) || {}
  const isSelectedValue = Object.entries(values).some(([, value]) => value)

  const handleOk = () => {
    const formValues = Object.fromEntries(
      Object.entries(form.getValues()).filter(
        ([, value]) => value !== undefined,
      ),
    )

    closeModal()
    setValue(name, formValues)
  }

  const handleCancel = () => {
    closeModal()
    form.reset(getDefaultValuesForm())
  }

  return (
    <>
      <Button
        onClick={openModal}
        variant='whiteBorder'
        uppercase
        endDecorator={
          isSelectedValue && (
            <IonIcon
              icon={checkmarkOutline}
              style={{
                color: myPalette.green.main,
                fontSize: '16px',
                '--ionicon-stroke-width': '60px',
              }}
            />
          )
        }
      >
        {buttonTitle}
      </Button>
      <Modal
        isShow={isModalShow}
        onCancel={handleCancel}
        onOk={handleOk}
        ok='Добавить'
      >
        <Title>{title}</Title>
        <Description>{description}</Description>

        <FormProvider form={form}>
          <CheckBoxListWrapper>
            {options.map((option) => (
              <Checkbox key={option.id} label={option.label} name={option.id} />
            ))}
          </CheckBoxListWrapper>
        </FormProvider>
      </Modal>
    </>
  )
}
