import styled from 'styled-components'

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
`

export const Description = styled.div`
  font-size: 16px;
  margin-bottom: 26px;
`
