import { Fragment } from 'react'
import { IonList, Skeleton } from './styled'

type SkeletonListProps = {
  count: number
  skeletonHeight?: number
  itemMarginBottom?: string
}

export function SkeletonList({
  count,
  skeletonHeight = 100,
}: SkeletonListProps) {
  const skeletons = []

  for (let i = 0; i < count; i++) {
    skeletons.push(<Skeleton animated $height={skeletonHeight} />)
  }

  return (
    <IonList>
      {skeletons.map((skeleton, index) => (
        <Fragment key={index}>{skeleton}</Fragment>
      ))}
    </IonList>
  )
}
