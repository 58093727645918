import { useStore } from 'effector-react'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { inspectionFormModel } from '~/entities/Inspection'
import { $savingPhotosCount, savePhoto } from '~/entities/Inspection/model'
import {
  InspectionPhotoTypeEnum,
  InspectionReasonCodeEnum,
} from '~/shared/config/enums'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Button } from '~/shared/ui/Buttons'
import { Camera, Checkbox, FormProvider, Switch } from '~/shared/ui/form'
import { Skeleton } from '~/shared/ui/SkeletonList'
import { FormHeader } from '../FormHeader'
import {
  TariffsPhotosErrorMessage,
  Wrapper,
  CheckBoxListWrapper,
} from './styled'
import { CarCabinInspectionFormValues } from './types'

const peculiarities = [
  { id: 'hasTobaccoSmell', label: 'Запах табака' },
  { id: 'isInteriorDirty', label: 'Грязный салон' },
  { id: 'isDryCleaningNeed', label: 'Требуется химчистка' },
]

type CarBodyInspectionFormProps = {
  onSuccess: () => void
  form: UseFormReturn<CarCabinInspectionFormValues>
  reasonCode: InspectionReasonCodeEnum
}

export function CarCabinInspectionForm({
  onSuccess,
  form,
  reasonCode,
}: CarBodyInspectionFormProps) {
  const savingPhotoCount =
    useStore($savingPhotosCount)[InspectionPhotoTypeEnum.TARIFFS]
  const isPhotoPending = savingPhotoCount > 0

  const isTariffsPhotosRequired =
    reasonCode === InspectionReasonCodeEnum.PLAN ||
    reasonCode === InspectionReasonCodeEnum.RENTAL

  const carEquipmentsInteriorOptions = useStore(
    inspectionFormModel.$carEquipmentsInteriorOptions,
  )
  const isCarEquipmentsPending = useStore(
    inspectionFormModel.requestCarEquipmentsFx.pending,
  )

  const [tariffsPhotosErrorMessage, setTariffsPhotosErrorMessage] =
    useState<string>()

  const tariffsPhotos = form.watch('tariffsPhotos')

  useEffect(() => {
    if (tariffsPhotos?.length) {
      setTariffsPhotosErrorMessage(undefined)
    }
  }, [tariffsPhotos])

  const handleOnSuccess = () => {
    if (!tariffsPhotos?.length && isTariffsPhotosRequired) {
      setTariffsPhotosErrorMessage('Загрузите фото тарифной сетки')
      snackbarEnqueued({
        message: 'Для перехода на следующий шаг загрузите фото тарифной сетки',
        variant: 'warning',
      })
      return
    }
    onSuccess()
  }

  return (
    <FormProvider form={form} onSuccess={handleOnSuccess}>
      <Wrapper>
        <Camera
          buttonTitle='Фото тарифной сетки'
          name='tariffsPhotos'
          saveFileFn={(file) =>
            savePhoto({
              file,
              type: InspectionPhotoTypeEnum.TARIFFS,
              form: form,
              fieldName: 'tariffsPhotos',
            })
          }
          isLoading={isPhotoPending}
        />
        {tariffsPhotosErrorMessage && (
          <TariffsPhotosErrorMessage data-testid='tariffs-photos-error-message'>
            {tariffsPhotosErrorMessage}
          </TariffsPhotosErrorMessage>
        )}

        <FormHeader title='Комплектность' />

        {isCarEquipmentsPending && <Skeleton animated $height={150} />}

        <CheckBoxListWrapper>
          {!isCarEquipmentsPending &&
            carEquipmentsInteriorOptions.map((param) => (
              <Checkbox
                key={param.id}
                label={param.label}
                name={`equipmentsInterior.${param.id}`}
              />
            ))}
        </CheckBoxListWrapper>

        <FormHeader title='Особенности' />

        {peculiarities.map((param) => (
          <Switch key={param.id} label={param.label} name={param.id} />
        ))}

        <Button
          fullWidth
          type='submit'
          variant='limeFlooded'
          uppercase
          style={{ marginTop: '16px' }}
        >
          Осмотреть багажник
        </Button>
      </Wrapper>
    </FormProvider>
  )
}
