import { IonSpinner } from '@ionic/react'
import { type ComponentProps, ReactNode } from 'react'
import { ProgressWrapper, Wrapper } from './styled'

type Props = {
  children: ReactNode
  loading?: boolean
  spinnerProps?: ComponentProps<typeof IonSpinner>
}

export function ProgressOverlay({ children, loading, spinnerProps }: Props) {
  return (
    <Wrapper $loading={loading}>
      {children}
      <ProgressWrapper hidden={!loading}>
        <IonSpinner
          name='bubbles'
          {...spinnerProps}
          data-testid='circular-progress'
        />
      </ProgressWrapper>
    </Wrapper>
  )
}
