import { useState } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { logger } from '~/shared/lib/logger'
import { Modal } from '../Modal'
import { Description, Title } from './styled'

export function ReloadSw() {
  const [isLoading, toggleIsLoading] = useState<boolean>(false)
  const [sw, swSet] = useState<ServiceWorkerRegistration | undefined>()

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl: string, r: ServiceWorkerRegistration | undefined) {
      if (!r) return

      setInterval(async () => {
        if (r.installing || !navigator) return

        if ('connection' in navigator && !navigator.onLine) return

        try {
          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          })

          if (resp?.status === 200) {
            await r.update()
            swSet(r)
          }
        } catch (e) {
          logger.error(e)
        }
      }, 60000)
    },
  })

  const handleReload = async () => {
    toggleIsLoading(true)
    await updateServiceWorker()

    setTimeout(async () => {
      await sw?.unregister()
      location.reload()
    }, 3000)
  }

  return (
    <Modal
      isShow={needRefresh}
      onOk={handleReload}
      ok='Обновить'
      isLoading={isLoading}
    >
      <Title>Доступна новая версия.</Title>
      <br />
      <Description>
        Обновите приложение для дальнейшего использования!
      </Description>
    </Modal>
  )
}
