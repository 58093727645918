import { Skeleton } from '~/shared/ui/SkeletonList'

export function LoadingBlock() {
  return (
    <>
      <Skeleton animated $height={36} style={{ marginBottom: '12px' }} />
      <Skeleton animated $height={95} style={{ marginBottom: '30px' }} />
      <Skeleton animated $height={57} style={{ marginBottom: '14px' }} />
      <Skeleton animated $height={67} style={{ marginBottom: '14px' }} />
      <Skeleton animated $height={46} style={{ marginBottom: '2px 0' }} />
      <Skeleton animated $height={46} style={{ marginBottom: '2px 0' }} />
      <Skeleton animated $height={46} style={{ marginBottom: '2px 0 16px' }} />
      <Skeleton animated $height={52} />
    </>
  )
}
