import { useStore } from 'effector-react'
import { AlreadyAuthorized, AuthForms } from '~/widgets/Auth'
import { getUserMeFx, loginFx } from '~/features/auth/login/model'
import { viewerModel } from '~/entities/Viewer'

function LoginPage() {
  const authenticated = viewerModel.useAuthenticated()
  const isLoginPending = useStore(loginFx.pending)
  const isMePending = useStore(getUserMeFx.pending)

  return (
    <>
      {authenticated && !isLoginPending && !isMePending ? (
        <AlreadyAuthorized />
      ) : (
        <AuthForms />
      )}
    </>
  )
}

export default LoginPage
