import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const FilesContainer = styled.div<{ gridTemplateColumns?: string }>`
  display: grid;
  grid-template-columns: repeat(
    ${({ gridTemplateColumns }) => gridTemplateColumns || '2, 1fr'}
  );
  grid-gap: 8px;
  margin-top: 16px;
`
export const FileWrap = styled.div`
  position: relative;
  display: flex;
  border-radius: 2px;
  border: 1px solid ${myPalette.dark['300']};
  background: ${myPalette.dark['100']};
  aspect-ratio: 1 / 1;
  padding: 2px;
`
export const ThumbActions = styled.div`
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  width: 100%;
  justify-content: space-around;
`
