import { IonIcon } from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons'
import { backFx } from '~/shared/lib/history'
import { Button } from '~/shared/ui/Buttons'

type ButtonBackProps = {
  backFn?: () => void
  title?: string
}

export function ButtonBack({ backFn, title = 'Назад' }: ButtonBackProps) {
  const onBack = backFn ? backFn : backFx

  return (
    <Button
      variant='textWhite'
      onClick={() => onBack()}
      startDecorator={
        <IonIcon icon={arrowBackOutline} style={{ fontSize: '26px' }} />
      }
      contentProps={{
        style: { padding: 0, fontSize: '20px' },
      }}
      data-testid='back-button'
    >
      {title}
    </Button>
  )
}
