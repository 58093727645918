import {
  IonButton as IonicIonButton,
  IonSpinner as IonicIonSpinner,
} from '@ionic/react'
import { styled } from 'styled-components'
import { myPalette } from '~/shared/ui/theme'
import { Variant } from './Button'

export const IonButton = styled(IonicIonButton)<{
  $variant: Variant
  $fullWidth?: boolean
  $uppercase?: boolean
}>`
  margin: 0;
  font-size: 16px;
  min-height: auto;

  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-right: 0px;
  --padding-left: 0px;
  --color: ${myPalette.black.main};
  --opacity: 1;
  --border-radius: 4px;

  text-transform: ${({ $uppercase }) => ($uppercase ? 'uppercase' : 'none')};

  ${({ $fullWidth }) => {
    if ($fullWidth) {
      return {
        width: '100%',
      }
    }
  }}

  &[disabled='true'] {
    opacity: 0.6;
  }

  &::part(native) {
    padding: 0;
  }

  ${({ $variant }) => {
    if ($variant === 'limeFlooded') {
      return {
        '--background': myPalette.limeGreen.main,
        '--background-activated': myPalette.limeGreen['700'],
        '--background-hover': myPalette.limeGreen['700'],
      }
    }
    if ($variant === 'crimsonOutline') {
      return {
        '--color': myPalette.crimson.main,
        '--background': myPalette.white.main,
        '--background-activated': myPalette.crimson['100'],
        '--background-hover': myPalette.crimson['100'],
      }
    }
    if ($variant === 'primaryOutline') {
      return {
        '--color': myPalette.primary.main,
        '--background': myPalette.white.main,
        '--background-activated': myPalette.primary['100'],
        '--background-hover': myPalette.primary['100'],
      }
    }
    if ($variant === 'whiteBorder') {
      return {
        '--color': myPalette.black.main,
        '--background': myPalette.white.main,
        '--background-activated': myPalette.white['100'],
        '--background-hover': myPalette.white['100'],
        '--border-radius': '6px',
        '--border-color': myPalette.black.main,
        '--border-style': 'solid',
        '--border-width': '1px',
      }
    }
    if ($variant === 'warningBorder') {
      return {
        '--color': myPalette.orange.main,
        '--background': myPalette.white.main,
        '--background-activated': myPalette.white['100'],
        '--background-hover': myPalette.white['100'],
        '--border-radius': '6px',
        '--border-color': myPalette.orange.main,
        '--border-style': 'solid',
        '--border-width': '1px',
      }
    }
    if ($variant === 'text') {
      return {
        '--color': myPalette.black.main,
        '--background': 'transparent',
        '--background-activated': 'transparent',
        '--background-hover': 'transparent',
      }
    }
    if ($variant === 'textBlue') {
      return {
        '--color': myPalette.blue.cornflowerBlue,
        '--background': 'transparent',
        '--background-activated': 'transparent',
        '--background-hover': 'transparent',
      }
    }
    if ($variant === 'textCrimson') {
      return {
        '--color': myPalette.crimson.main,
        '--background': 'transparent',
        '--background-activated': 'transparent',
        '--background-hover': 'transparent',
      }
    }
    if ($variant === 'textWhite') {
      return {
        '--color': myPalette.white.main,
        '--background': 'transparent',
        '--background-activated': 'transparent',
        '--background-hover': 'transparent',
      }
    }
  }}
`

export const ButtonContent = styled.div`
  padding: 13px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EndDecoratorWrapper = styled.div`
  margin-left: 4px;
  display: flex;
`

export const StartDecoratorWrapper = styled.div`
  margin-right: 4px;
  display: flex;
`

export const IonSpinner = styled(IonicIonSpinner)`
  width: 16px;
  height: 16px;
`
