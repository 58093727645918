import { ComponentProps } from 'react'
import { Controller } from 'react-hook-form'
import { IonRadioGroup, IonRadio } from './styled'

type Option = { id: UniqueId; label: string }

type RadioGroupProps = {
  name: string
  options: Option[]
} & ComponentProps<typeof IonRadioGroup>

const compareWith = (groupId: UniqueId, itemId: UniqueId) => {
  return groupId === itemId
}

export function RadioGroup({ name, options, ...groupProps }: RadioGroupProps) {
  return (
    <Controller
      name={name}
      defaultValue=''
      render={({ field: { onChange, value } }) => {
        return (
          <IonRadioGroup
            name={name}
            value={value}
            compareWith={compareWith}
            onIonChange={(e) => {
              onChange(e.detail.value)
            }}
            {...groupProps}
          >
            {options.map(({ id, label }) => (
              <IonRadio
                mode='md'
                key={id}
                value={id}
                labelPlacement='end'
                justify='start'
              >
                {label}
              </IonRadio>
            ))}
          </IonRadioGroup>
        )
      }}
    />
  )
}
