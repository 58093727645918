import { IonIcon as IonicIonIcon } from '@ionic/react'
import styled from 'styled-components'
import { Button as DefaultButton } from '~/shared/ui/Buttons'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled.div`
  cursor: pointer;
  height: 100px;
  border-radius: 16px;
  background: ${myPalette.white.main};
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 5px 0 rgba(0, 21, 70, 0.1);
  margin-bottom: 8px;
`

export const ContentWrapper = styled.div`
  padding: 16px 24px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

export const Button = styled(DefaultButton)`
  min-width: 83px;
  --border-radius: 0 16px 16px 0;
`

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IonIcon = styled(IonicIonIcon)`
  font-size: 22px;
  margin-bottom: 6px;
`
