import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 100vh;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`
