import { Wrapper, ContentWrapper, Description } from './styled'

type CardProps = {
  plateNumber: string
  reason: string
  time: string
}

export function Card({ plateNumber, reason, time }: CardProps) {
  return (
    <Wrapper>
      <ContentWrapper>
        <div style={{ fontSize: '24px' }}>{plateNumber}</div>
        <Description>
          <div style={{ fontSize: '14px' }}>{reason}</div>
          <div style={{ fontSize: '14px' }}>{time}</div>
        </Description>
      </ContentWrapper>
    </Wrapper>
  )
}
