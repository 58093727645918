import { useCallback, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { Media } from '~/shared/api'
import { Zoom } from '~/shared/ui/Zoom'
import { ProgressBlock } from '../Loaders'
import { Wrapper } from './styled'

type Image = {
  id: UniqueId
  src: string
}

type ImageSliderProps = {
  images: Image[]
  visibleImageId?: string
}

export function ImageSlider({ images, visibleImageId }: ImageSliderProps) {
  const sliderRef = useRef<Slider>(null)
  const [isSlidersLoading, toggleSlidersLoading] = useState<boolean>(true)
  const [sliders, setSliders] = useState<Image[]>([])

  const handleMediaGet = useCallback(async () => {
    if (sliders?.length) return

    try {
      toggleSlidersLoading(true)
      const resultSliders = []

      for (const image of images) {
        const blob = await Media.getMedia(image.src)
        resultSliders.push({ ...image, src: URL.createObjectURL(blob) })
      }

      setSliders(resultSliders)
    } finally {
      toggleSlidersLoading(false)
    }
  }, [images, sliders?.length])

  useEffect(() => {
    handleMediaGet()
    // eslint-disable-next-line
  }, [images])

  useEffect(() => {
    if (visibleImageId) {
      sliderRef.current?.slickGoTo(
        images.findIndex(({ id }) => id === visibleImageId),
        true,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleImageId])

  return (
    <Wrapper>
      <Slider
        ref={sliderRef}
        dots
        arrows={false}
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        customPaging={(index: number) => (
          <img src={sliders[index]?.src} alt='' />
        )}
      >
        {isSlidersLoading && <ProgressBlock size={28} />}
        {!isSlidersLoading &&
          sliders.map((img) => (
            <Zoom key={img.id}>
              <img src={img.src} alt='' />
            </Zoom>
          ))}
      </Slider>
    </Wrapper>
  )
}
