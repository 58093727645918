import { IonInfiniteScroll } from '@ionic/react'
import { uniqBy } from 'lodash'
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDebounce } from 'use-debounce'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { logger } from '~/shared/lib/logger'
import { ProgressBlock } from '../Loaders'
import { SkeletonList } from '../SkeletonList'
import {
  SCROLLABLE_LIST_DEFAULT_VALUES,
  SCROLLABLE_LIST_PAGE_KEY,
} from './constants'
import { IonList } from './styled'
import { FetchFn, Item, ListData } from './types'

type ScrollableListProps<Row extends Item> = {
  pageSizeParams?: number
  skeletonHeight?: number
  fetchFn: FetchFn<Row>
  externalFilter?: { key: string; value: string }[]
  empty?: ReactNode
  mapItems: (items: Row[]) => ReactNode
}

export function ScrollableList<Row extends Item>({
  pageSizeParams = 10,
  skeletonHeight,
  fetchFn,
  externalFilter,
  empty,
  mapItems,
}: ScrollableListProps<Row>) {
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement | null>(null)
  const [data, setData] = useState<ListData<Row>>()
  const isMounted = useRef<boolean>(true)
  const [debouncedExternalFilter] = useDebounce(
    JSON.stringify(externalFilter || []),
    400,
  )
  const [isSkeletonShow, toggleIsSkeletonShow] = useState<boolean>(false)
  const [isFetching, toggleIsFetching] = useState(false)
  const {
    items = [],
    to,
    currentPage,
    lastPage,
  } = useMemo(() => data || SCROLLABLE_LIST_DEFAULT_VALUES, [data])

  const [pageQuery, setPageQuery] = useQueryParam(
    SCROLLABLE_LIST_PAGE_KEY,
    withDefault(NumberParam, 1),
  )

  const fetchData = useCallback(async () => {
    try {
      toggleIsFetching(true)
      const pageSize =
        isMounted.current && pageQuery
          ? pageQuery * pageSizeParams
          : pageSizeParams

      const page = isMounted.current && pageQuery ? undefined : pageQuery

      const { items, currentPage, lastPage, to } = await fetchFn(
        page,
        pageSize,
        externalFilter?.length ? externalFilter : [],
      )

      setData((data) => ({
        items: uniqBy([...(data?.items || []), ...(items || [])], 'id'),
        currentPage,
        lastPage,
        to,
      }))
    } catch (e) {
      logger.error('Load scrollable list failed', e)
    } finally {
      isMounted.current = false
      toggleIsSkeletonShow(false)
      toggleIsFetching(false)
      infiniteScrollRef.current?.complete()
    }
  }, [externalFilter, fetchFn, pageQuery, pageSizeParams])

  const handlePageChange = (pageParams?: number) => {
    setPageQuery(pageParams ?? pageQuery + 1, 'replaceIn')
  }

  const filtrationByExternalFilter = () => {
    if (isMounted.current) return
    toggleIsSkeletonShow(true)
    setData(undefined)
    pageQuery === 1 ? fetchData() : handlePageChange(1)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageQuery])

  useEffect(() => {
    filtrationByExternalFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedExternalFilter])

  if (isMounted.current || isSkeletonShow)
    return (
      <SkeletonList count={pageSizeParams} skeletonHeight={skeletonHeight} />
    )

  if (!to) {
    return (
      <div style={{ fontSize: '16px', margin: 0 }}>
        {empty || 'Нечего не найдено'}
      </div>
    )
  }

  return (
    <>
      <IonList>{mapItems(items)}</IonList>

      <IonInfiniteScroll
        threshold='200px'
        ref={infiniteScrollRef}
        onIonInfinite={() => {
          if (isFetching) return
          handlePageChange()
        }}
        disabled={currentPage >= lastPage}
      >
        <ProgressBlock height={32} size={28} />
      </IonInfiniteScroll>
    </>
  )
}
