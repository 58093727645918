import styled from 'styled-components'

export const Wrapper = styled.div<{ $loading?: boolean }>`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 6px 6px 0 0;
    transition: 0.3s visibility ease, 0.3s opacity ease;
    opacity: ${({ $loading }) => ($loading ? `1` : '0')};
    visibility: ${({ $loading }) => ($loading ? `visible` : 'hidden')};
  }
`

export const ProgressWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 0;
`
