import { useStore } from 'effector-react'
import { useCallback } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  $lastServices,
  $savingPhotosCount,
  savePhoto,
} from '~/entities/Inspection/model'
import {
  replacingHeadlightsOptions,
  replacingWipersOptions,
} from '~/shared/config/constants'
import {
  InspectionPhotoTypeEnum,
  InspectionServiceTypeEnum,
} from '~/shared/config/enums'
import { formatDateForUI } from '~/shared/lib/date'
import { Button } from '~/shared/ui/Buttons'
import {
  ModalButtonCheckboxes,
  ModalButtonRange,
  Camera,
  FormProvider,
} from '~/shared/ui/form'
import { Card, CardDescription, CardTitle, Wrapper } from './styled'
import { CarUnderHoodInspectionFormValues } from './types'

type CarUnderHoodInspectionFormProps = {
  onSuccess: () => void
  form: UseFormReturn<CarUnderHoodInspectionFormValues>
}

export function CarUnderHoodInspectionForm({
  onSuccess,
  form,
}: CarUnderHoodInspectionFormProps) {
  const savingPhotoCount =
    useStore($savingPhotosCount)[InspectionPhotoTypeEnum.LAST_TO]
  const isPhotoPending = savingPhotoCount > 0

  const lastServices = useStore($lastServices)

  const getLastServicesDateByType = useCallback(
    (type: InspectionServiceTypeEnum) => {
      const foundLastServices = lastServices?.find(
        (service) => service.type === type,
      )
      return foundLastServices?.createdAt
        ? formatDateForUI(foundLastServices.createdAt)
        : null
    },
    [lastServices],
  )

  const motorOilLastDate = getLastServicesDateByType(
    InspectionServiceTypeEnum.MOTOR_OIL,
  )
  const antifreezeLastDate = getLastServicesDateByType(
    InspectionServiceTypeEnum.ANTIFREEZE,
  )
  const wipersLastDate = getLastServicesDateByType(
    InspectionServiceTypeEnum.WIPERS,
  )
  const lampsLastDate = getLastServicesDateByType(
    InspectionServiceTypeEnum.LAMPS,
  )

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Wrapper>
        <Card>
          <div>
            <CardTitle>Долив масла</CardTitle>
            {motorOilLastDate && (
              <>
                <CardDescription>Последнее действие</CardDescription>
                <CardDescription>{motorOilLastDate}</CardDescription>
              </>
            )}
          </div>
          <ModalButtonRange
            title='Долив масла'
            buttonTitle='Добавить'
            description='Выберите количество мл, которое необходимо долить'
            suffix=' мл'
            step={50}
            name={InspectionServiceTypeEnum.MOTOR_OIL}
          />
        </Card>

        <Card>
          <div>
            <CardTitle>Долив антифриза</CardTitle>
            {antifreezeLastDate && (
              <>
                <CardDescription>Последнее действие</CardDescription>
                <CardDescription>{antifreezeLastDate}</CardDescription>
              </>
            )}
          </div>
          <ModalButtonRange
            title='Долив антифриза'
            buttonTitle='Добавить'
            description='Выберите количество мл, которое необходимо долить'
            suffix=' мл'
            step={50}
            name={InspectionServiceTypeEnum.ANTIFREEZE}
          />
        </Card>

        <Card>
          <div>
            <CardTitle>Замена дворников</CardTitle>
            {wipersLastDate && (
              <>
                <CardDescription>Последнее действие</CardDescription>
                <CardDescription>{wipersLastDate}</CardDescription>
              </>
            )}
          </div>
          <ModalButtonCheckboxes
            title='Замена дворников'
            buttonTitle='Добавить'
            description='Укажите дворник, который подлежит замене'
            name={InspectionServiceTypeEnum.WIPERS}
            options={replacingWipersOptions}
          />
        </Card>

        <Card>
          <div>
            <CardTitle>Замена лампочек</CardTitle>
            {lampsLastDate && (
              <>
                <CardDescription>Последнее действие</CardDescription>
                <CardDescription>{lampsLastDate}</CardDescription>
              </>
            )}
          </div>
          <ModalButtonCheckboxes
            title='Замена лампочек'
            buttonTitle='Добавить'
            description='Укажите фару в которой нужно заменить лампочку'
            name={InspectionServiceTypeEnum.LAMPS}
            options={replacingHeadlightsOptions}
          />
        </Card>

        <div style={{ padding: '16px 0' }}>
          <Camera
            buttonTitle='Добавить фото бирки ТО'
            name='lastTOPhotos'
            saveFileFn={(file) =>
              savePhoto({
                file,
                type: InspectionPhotoTypeEnum.LAST_TO,
                form,
                fieldName: 'lastTOPhotos',
              })
            }
            isLoading={isPhotoPending}
          />
        </div>

        <Button
          fullWidth
          type='submit'
          variant='limeFlooded'
          uppercase
          style={{ marginTop: '16px' }}
        >
          Далее
        </Button>
      </Wrapper>
    </FormProvider>
  )
}
