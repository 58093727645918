import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const TariffsPhotosErrorMessage = styled.div`
  font-size: 12px;
  color: ${myPalette.red.main};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CheckBoxListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 16px;
`
