import { IonList as IonicIonList, IonSkeletonText } from '@ionic/react'
import styled from 'styled-components'

export const IonList = styled(IonicIonList)`
  background: transparent;
  padding: 8px 0;
`

export const Skeleton = styled(IonSkeletonText)<{ $height: number }>`
  width: 100%;
  height: ${({ $height }) => `${$height}px`};
  border-radius: 16px;
  margin-bottom: 8px;
`
