import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 12px;
`

export const Title = styled.div`
  font-size: 24px;
`
