import './App.css'

import { IonApp, IonContent, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useGate } from 'effector-react'
import { parse, stringify } from 'query-string'
import { HelmetProvider } from 'react-helmet-async'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { Routing } from '~/pages'
import { CameraProvider } from 'src/widgets/CameraProvider'
import { isMobileApp } from '~/shared/config/constants'
import { isDevEnv } from '~/shared/config/env'
import { history } from '~/shared/lib/history'
import { NotificationsProvider } from '~/shared/lib/notifications'
import { sentryModel } from '~/shared/lib/sentry'
import { ErrorBoundary } from '~/shared/ui/ErrorBoundary'
import { ReloadApp } from '~/shared/ui/ReloadApp'
import { ReloadSw } from '~/shared/ui/ReloadSw'
import { BODY_MAX_WIDTH } from '~/shared/ui/theme'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

const queryClient = new QueryClient()

setupIonicReact({
  mode: 'ios',
})

export function App() {
  useGate(sentryModel.SentryGate)

  return (
    <ErrorBoundary>
      <IonReactRouter history={history}>
        <CameraProvider>
          <IonApp
            style={{
              alignItems: 'center',
            }}
          >
            <IonContent
              style={{
                maxWidth: BODY_MAX_WIDTH,
              }}
            >
              {!isMobileApp && <ReloadSw />}
              {isMobileApp && !isDevEnv && <ReloadApp />}

              <NotificationsProvider>
                <QueryParamProvider
                  adapter={ReactRouter5Adapter}
                  options={{
                    searchStringToObject: parse,
                    objectToSearchString: stringify,
                  }}
                >
                  <QueryClientProvider client={queryClient}>
                    <HelmetProvider>
                      <Routing />
                    </HelmetProvider>
                  </QueryClientProvider>
                </QueryParamProvider>
              </NotificationsProvider>
            </IonContent>
          </IonApp>
        </CameraProvider>
      </IonReactRouter>
    </ErrorBoundary>
  )
}
