import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled.div<{ $background?: string | null }>`
  background: ${({ $background }) =>
    $background || "url('/images/marker.svg') no-repeat"};
  background-size: 100%;
  width: 31px;
  height: 31px;
  border-radius: 100%;
  position: absolute;
  left: -15px;
  top: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${myPalette.white.main};
`
