import { IonSpinner } from '@ionic/react'
import { useCallback, useEffect, useState } from 'react'
import { Media } from '~/shared/api'
import { logger } from '~/shared/lib/logger'

import { Image, WrapperLoading } from './styled'

type ImgProps = {
  url: string
}

export function Img({ url }: ImgProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [imageUrl, setImageUrl] = useState<string>()

  const handleMediaGet = useCallback(async () => {
    try {
      setIsLoading(true)
      const blob = await Media.getMedia(url)

      setImageUrl(URL.createObjectURL(blob))
    } catch (e) {
      logger.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [url])

  useEffect(() => {
    handleMediaGet()
    // eslint-disable-next-line
  }, [url])

  if (isLoading) {
    return (
      <WrapperLoading data-testid='img-loading'>
        <IonSpinner name='bubbles' data-testid='circular-progress' />
      </WrapperLoading>
    )
  }

  return <Image src={imageUrl} alt='img' />
}
