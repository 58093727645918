import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid ${myPalette.blue.lightSteelBlue};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CardTitle = styled.div`
  font-size: 18px;
`

export const CardDescription = styled.div`
  font-size: 14px;
  color: ${myPalette.dimGray.main};
`
