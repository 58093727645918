import { IonInput as IonicIonInput } from '@ionic/react'
import styled from 'styled-components'

export const IonInput = styled(IonicIonInput)<{ $invalid: boolean }>`
  .input-bottom {
    .helper-text {
      display: ${({ $invalid }) => ($invalid ? 'none' : 'block')} !important;
    }
    .error-text {
      display: ${({ $invalid }) => ($invalid ? 'block' : 'none')} !important;
    }
  }
`
