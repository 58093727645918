import styled from 'styled-components'

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
`

export const Description = styled.div`
  font-size: 16px;
`

export const Ul = styled.ul`
  margin: 16px 0;
  padding-left: 20px;
`

export const LI = styled.li`
  font-weight: 500;
`

export const Question = styled.div`
  font-size: 16px;
`
