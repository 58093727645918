const LIME_GREEN = {
  main: '#D6F337',
  '700': '#d0e84e',
}

const ALICE_BLUE = {
  main: '#F8FAFF',
}

const WHITE = {
  main: '#fff',
  '100': '#F8FAFF',
}
const BLACK = {
  main: '#181818',
}

const PRIMARY_LIGHT = {
  main: '#EAEEF9',
}

const PRUSSIAN_BLUE = {
  main: '#102860',
}

const DIM_GRAY = {
  main: '#656565',
}

const GREY = {
  main: '#8E94A3',
  '100': '#f5f5f5',
  '300': '#e0e0e0',
  drawerTopLine: '#E8ECF2',
  jetBlack: 'rgba(0, 0, 0, 0.12)',
}

const BLUE = {
  main: '#1047CA',
  light: '#E4ECFF',
  lightGrayishBlue: '#E8ECF2',
  paleBlue: '#F8FAFF',
  lightSteelBlue: '#B2BDD6',
  cornflowerBlue: '#4E6CB1',
}

const RED = {
  main: '#F84646',
}

const CRIMSON = {
  main: '#D32F2F',
  '100': '#d32f2e12',
}

const PRIMARY = {
  main: '#2196F3',
  '100': '#2196f31c',
}

const ORANGE = {
  main: '#ed6c02',
  '100': '#ff9800',
}

const GREEN = {
  main: '#2e7d32',
}

export const myPalette = {
  limeGreen: LIME_GREEN,
  aliceBlue: ALICE_BLUE,
  white: WHITE,
  black: BLACK,
  // TODO: rename dark to grey
  dark: GREY,
  blue: BLUE,
  primaryLight: PRIMARY_LIGHT,
  prussianBlue: PRUSSIAN_BLUE,
  dimGray: DIM_GRAY,
  red: RED,
  primary: PRIMARY,
  crimson: CRIMSON,
  orange: ORANGE,
  green: GREEN,
}
