import { Modal } from '~/shared/ui/Modal'
import { Title, Description, Ul, LI, Question } from './styled'

type NotCompleteSetModalProps = {
  isShow: boolean
  onCancel: () => void
  onOk: () => void
  values?: string[]
}

export function NotCompleteSetModal({
  isShow,
  onCancel,
  onOk,
  values,
}: NotCompleteSetModalProps) {
  return (
    <Modal
      isShow={isShow}
      onCancel={onCancel}
      onOk={onOk}
      cancel='Нет'
      ok='Да'
      reverseButton
    >
      <Title>Не полная комплектация</Title>
      <Description>
        На текущем осмотре выявлена нехватка комплектности
      </Description>

      <Ul>
        {values?.map((value) => (
          <LI key={value}>{value}</LI>
        ))}
      </Ul>
      <Question>Хотите продолжить?</Question>
    </Modal>
  )
}
