import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled.div`
  background: ${myPalette.white.main};
  border: 1px solid ${myPalette.orange.main};
  border-radius: 6px;
  padding: 16px;
  margin: 36px 0;
`

export const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
`

export const Description = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`
