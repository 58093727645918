import { IonSpinner } from '@ionic/react'
import { Wrapper } from './styled'

type ProgressHeadingProps = {
  height?: number
  size?: number
}

export function ProgressBlock({ height = 300, size }: ProgressHeadingProps) {
  const styleSize = size ? { width: `${size}px`, height: `${size}px` } : {}

  return (
    <Wrapper $height={height}>
      <IonSpinner name='bubbles' style={{ ...styleSize }} />
    </Wrapper>
  )
}
