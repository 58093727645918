import { useGate, useStore } from 'effector-react'
import { Modal } from '../Modal'
import { $needUpdateApp, Gate, openStore, openStoreFx } from './model'
import { Description, Title } from './styled'

export function ReloadApp() {
  useGate(Gate)

  const needUpdateApp = useStore($needUpdateApp)
  const isLoading = useStore(openStoreFx.pending)

  return (
    <Modal
      isShow={needUpdateApp}
      onOk={() => openStore()}
      ok='Обновить'
      isLoading={isLoading}
    >
      <Title>Доступна новая версия.</Title>
      <br />
      <Description>
        Обновите приложение для дальнейшего использования!
      </Description>
    </Modal>
  )
}
