import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.div<{ $isError: boolean }>`
  font-size: 22px;
  font-weight: 600;
  color: ${({ $isError }) =>
    $isError ? myPalette.red.main : myPalette.primaryLight.main};
`
