import { MutableRefObject } from 'react'
import { Defect, useDefect } from '~/entities/Inspection'
import { PolygonMarkers } from '~/shared/ui/Polygon'
import carBodySvg from '~/shared/ui/Polygon/Images/carBody.svg'
import carEnvironmentSvg from '~/shared/ui/Polygon/Images/carEnvironment.svg'
import carInteriorSvg from '~/shared/ui/Polygon/Images/carInterior.svg'
import { Wrapper } from './styled'

type DamagesMapProps = {
  defectsCarBody: Defect[]
  defectsCarInterior: Defect[]
  defectsCarEnvironment: Defect[]
  refDamagesMap: MutableRefObject<HTMLDivElement>
}

type MapTypeProps = {
  defects: Defect[]
}

export function DamagesMap({
  defectsCarBody,
  defectsCarInterior,
  defectsCarEnvironment,
  refDamagesMap,
}: DamagesMapProps) {
  return (
    <div
      style={{
        position: 'fixed',
        top: '-150%',
      }}
    >
      <Wrapper ref={refDamagesMap}>
        <div
          style={{ zIndex: 1, position: 'relative', margin: '0 -20px 0 20px' }}
        >
          <BodyDamages defects={defectsCarBody} />
        </div>
        <InteriorDamages defects={defectsCarInterior} />
        <div
          style={{ zIndex: 1, position: 'relative', margin: '0 20px 0 -20px' }}
        >
          <EnvironmentDamages defects={defectsCarEnvironment} />
        </div>
      </Wrapper>
    </div>
  )
}

function BodyDamages({ defects }: MapTypeProps) {
  const { markers } = useDefect(defects)
  const newMarkers = markers.filter((marker) => !marker.isOld)
  return <PolygonMarkers markers={newMarkers} img={carBodySvg} />
}
function InteriorDamages({ defects }: MapTypeProps) {
  const { markers } = useDefect(defects)
  const newMarkers = markers.filter((marker) => !marker.isOld)
  return <PolygonMarkers markers={newMarkers} img={carInteriorSvg} />
}
function EnvironmentDamages({ defects }: MapTypeProps) {
  const { markers } = useDefect(defects)
  const newMarkers = markers.filter((marker) => !marker.isOld)
  return <PolygonMarkers markers={newMarkers} img={carEnvironmentSvg} />
}
