import { startOfDay } from 'date-fns'
import { useStore } from 'effector-react'
import { useCallback } from 'react'
import { viewerModel } from '~/entities/Viewer'
import { Inspection } from '~/shared/api'
import { formatForApi, getDuration } from '~/shared/lib/date'
import {
  Item,
  ScrollableList,
  FetchFn,
  SCROLLABLE_LIST_META_PAGE,
} from '~/shared/ui/ScrollableList'
import { Card } from './ui/Card'

type Row = Item & {
  id: UniqueId
  plateNumber: string
  reason: string
  duration: number
}

export function ListInspection() {
  const date = new Date()
  const startOfDayDate = startOfDay(date)
  const currentUserId = useStore(viewerModel.$currentUserId)

  const fetchRows = useCallback<FetchFn<Row>>(
    async (page, pageSize, filters) => {
      let builder = Inspection.limit(pageSize)
        .with('car')
        .with('reason')
        .where('createdAtGt', formatForApi(startOfDayDate))
        .option('[responsible][id]', currentUserId as string)
        .orderBy('-createdAt')

      filters?.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)
      const data = response.getData()

      const items = data.map((inspection) => {
        return {
          id: inspection?.getApiId() as string,
          plateNumber: inspection?.getCar()?.getPlateNumber(),
          reason: inspection?.getReason()?.getTitle(),
          duration: inspection?.getDuration(),
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const { to, currentPage, lastPage } =
        axiosResponse?.data?.meta?.page || SCROLLABLE_LIST_META_PAGE

      return {
        items,
        to,
        currentPage,
        lastPage,
      }
    },
    [currentUserId, startOfDayDate],
  )

  const handleMapItems = useCallback((items: Row[]) => {
    return items.map((item) => (
      <Card
        key={item.id}
        plateNumber={item.plateNumber}
        reason={item.reason}
        time={getDuration(item.duration)}
      />
    ))
  }, [])

  return (
    <ScrollableList
      fetchFn={fetchRows}
      empty='За сегодня осмотров еще не было'
      mapItems={handleMapItems}
    />
  )
}
