import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Title = styled.div`
  margin-bottom: 12px;
  font-size: 18px;
`

export const ItemsList = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Degree = styled.div`
  width: 25px;
  height: 25px;
  border: 1px solid ${myPalette.dark.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

export const Img = styled.img`
  margin-right: 6px;
  width: 25px;
`

export const Circle = styled.div<{ $background: string }>`
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 100%;
  margin-right: 6px;
  background: ${({ $background }) => $background};
`
