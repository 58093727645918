import { ReactNode } from 'react'
import { Wrapper, Title } from './styled'

type HeaderProps = {
  title: string
  extra?: ReactNode
}

export function Header({ title, extra }: HeaderProps) {
  return (
    <Wrapper>
      <Title>{title}</Title>

      {extra}
    </Wrapper>
  )
}
