import {
  IonSegmentCustomEvent,
  SegmentChangeEventDetail,
} from '@ionic/core/dist/types/components'
import { IonLabel } from '@ionic/react'
import { IonSpinner } from '~/shared/ui/Buttons/Button/styled'
import { IonSegment, IonSegmentButton } from './styled'

type ButtonGroupConfig<V> = {
  title: string
  loading?: boolean
  value: V
}

type ButtonGroupProps<V> = {
  config: ButtonGroupConfig<V>[]
  onClick: (value: V) => void
  value?: V
}

export function ButtonGroup<V extends string | number>({
  config,
  onClick,
  value,
}: ButtonGroupProps<V>) {
  return (
    <IonSegment
      value={value}
      onIonChange={(e: IonSegmentCustomEvent<SegmentChangeEventDetail>) =>
        onClick(e.detail.value as V)
      }
    >
      {config.map(({ title, loading, value }) => (
        <IonSegmentButton
          disabled={loading}
          key={value}
          value={value}
          layout='icon-end'
        >
          {loading && <IonSpinner name='bubbles' />}
          <IonLabel>{title}</IonLabel>
        </IonSegmentButton>
      ))}
    </IonSegment>
  )
}
