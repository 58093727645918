import { UseFormReturn } from 'react-hook-form'
import { CarBodyInspectionFormValues } from '~/entities/Inspection'
import { FormHeader } from '~/entities/Inspection/ui/FormHeader'
import {
  diskTypeEnumOptions,
  rubberTypeEnumOptions,
  wheelCodeEnumOptions,
} from '~/shared/config/constants'
import { WheelCodeEnum } from '~/shared/config/enums'
import { Checkbox, DrawerSelect } from '~/shared/ui/form'
import { WheelWrapper, Wrapper } from './styled'

type WheelsProps = {
  form: UseFormReturn<CarBodyInspectionFormValues>
}

const generateRubberTypeField = (key: WheelCodeEnum): never =>
  `wheels.${key}.rubberType` as never
const generateDiskTypeField = (key: WheelCodeEnum): never =>
  `wheels.${key}.diskType` as never

export function Wheels({ form }: WheelsProps) {
  const { watch, setValue, clearErrors } = form

  const isDiscrepanciesRubber = watch('isDiscrepanciesRubber')
  const isDiscrepanciesDisk = watch('isDiscrepanciesDisk')
  const canOneWheelChange = isDiscrepanciesRubber || isDiscrepanciesDisk

  watch((data, { name, type }) => {
    if (type !== 'change') return

    if (name === 'rubberType') {
      wheelCodeEnumOptions.forEach((wheel) => {
        setValue(generateRubberTypeField(wheel.id), data.rubberType as never)
      })
    }
    if (name === 'diskType') {
      wheelCodeEnumOptions.forEach((wheel) => {
        setValue(generateDiskTypeField(wheel.id), data.diskType as never)
      })
    }
    if (name === 'isDiscrepanciesRubber') {
      clearErrors('rubberType')

      if (isDiscrepanciesRubber) return
      wheelCodeEnumOptions.forEach((wheel) => {
        setValue(generateRubberTypeField(wheel.id), data.rubberType as never)
      })
    }
    if (name === 'isDiscrepanciesDisk') {
      clearErrors('diskType')

      if (isDiscrepanciesDisk) return
      wheelCodeEnumOptions.forEach((wheel) => {
        setValue(generateDiskTypeField(wheel.id), data.diskType as never)
      })
    }
  })

  return (
    <Wrapper>
      <DrawerSelect
        label='Тип резины'
        name='rubberType'
        title='Тип резины'
        defaultOptions={rubberTypeEnumOptions}
        validation={{
          required: !isDiscrepanciesRubber && 'Обязательное поле',
        }}
      />
      <DrawerSelect
        label='Тип дисков'
        name='diskType'
        title='Тип дисков'
        defaultOptions={diskTypeEnumOptions}
        validation={{ required: !isDiscrepanciesDisk && 'Обязательное поле' }}
      />
      <Checkbox
        label='Есть несоответствия в резине'
        name='isDiscrepanciesRubber'
      />
      <Checkbox
        label='Есть несоответствия в дисках'
        name='isDiscrepanciesDisk'
      />

      {canOneWheelChange &&
        wheelCodeEnumOptions.map((wheel) => (
          <WheelWrapper key={wheel.id}>
            <FormHeader title={wheel.label} />
            {isDiscrepanciesRubber && (
              <DrawerSelect
                label='Тип резины'
                name={`wheels.${wheel.id}.rubberType`}
                title='Тип резины'
                defaultOptions={rubberTypeEnumOptions}
                validation={{ required: 'Обязательное поле' }}
              />
            )}
            {isDiscrepanciesDisk && (
              <DrawerSelect
                label='Тип дисков'
                name={`wheels.${wheel.id}.diskType`}
                title='Тип дисков'
                defaultOptions={diskTypeEnumOptions}
                validation={{ required: 'Обязательное поле' }}
              />
            )}
          </WheelWrapper>
        ))}
    </Wrapper>
  )
}
