import styled from 'styled-components'

export const Title = styled.div`
  text-align: center;
  margin-bottom: 6px;
  font-size: 16px;
`

export const Wrapper = styled.div`
  margin-bottom: 16px;
`
