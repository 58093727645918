import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
`
