import { zodResolver } from '@hookform/resolvers/zod'
import { useStore } from 'effector-react'
import { eye, eyeOff } from 'ionicons/icons'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { FormProvider, TextInput } from '~/shared/ui/form'
import { myPalette } from '~/shared/ui/theme'
import { schema, FormValues } from './lib'
import { formSubmitted, getUserMeFx, loginFx } from './model'
import { PasswordWrapper, FormWrapper } from './styled'

export function Form() {
  const isPendingLogin = useStore(loginFx.pending)
  const isPendingMe = useStore(getUserMeFx.pending)

  const [isPassportVisible, setIsPassportVisible] = useState(false)

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
  })

  const handleVisibleChange = () => {
    setIsPassportVisible((prev) => !prev)
  }

  return (
    <FormProvider<FormValues> form={form} onSuccess={formSubmitted}>
      <FormWrapper>
        <TextInput label='E-mail' name='email' type='email' />

        <PasswordWrapper>
          <TextInput
            label='Пароль'
            name='password'
            type={isPassportVisible ? 'text' : 'password'}
          />

          <IconButton
            slot='end'
            icon={isPassportVisible ? eyeOff : eye}
            onClick={handleVisibleChange}
            style={{ minHeight: '40px', marginLeft: '6px' }}
            iconProps={{
              style: { color: myPalette.black.main, width: '34px' },
            }}
          />
        </PasswordWrapper>

        <Button
          type='submit'
          variant='limeFlooded'
          fullWidth
          loading={isPendingLogin || isPendingMe}
        >
          Далее
        </Button>
      </FormWrapper>
    </FormProvider>
  )
}
