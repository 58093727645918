import { arrowForwardOutline } from 'ionicons/icons'
import { CarAttributes } from '~/shared/api'
import {
  Wrapper,
  ContentWrapper,
  Button,
  ButtonContent,
  IonIcon,
} from './styled'

type CardProps = {
  onStart: () => void
  car: CarAttributes
}

export function Card({ onStart, car }: CardProps) {
  return (
    <Wrapper onClick={onStart}>
      <ContentWrapper>
        <div style={{ fontSize: '24px' }}>{car.plateNumber}</div>
        <div style={{ fontSize: '14px' }}>Прохождение ПО</div>
      </ContentWrapper>

      <Button data-testid='start-inspection-button'>
        <ButtonContent>
          <IonIcon icon={arrowForwardOutline} />
          Начать
        </ButtonContent>
      </Button>
    </Wrapper>
  )
}
