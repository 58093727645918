import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const MileageError = styled.div`
  font-size: 12px;
  margin-top: 4px;
  line-height: 12px;
  color: ${myPalette.red.main};
`

export const LatestRepairOrderDescription = styled.div`
  font-size: 16px;
`

export const CameraCarPhotosDescriptions = styled.div`
  font-size: 12px;
  margin: 4px 0 0;
  color: ${myPalette.dimGray.main};
`

export const ParamsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 8px;
`
