import { IonRouterOutlet } from '@ionic/react'
import React, { useEffect, useMemo } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { MinimalLayout } from '~/widgets/Layout/Minimal'
import { viewerModel } from '~/entities/Viewer'
import { isDevEnv } from '~/shared/config/env'
import { pushFx } from '~/shared/lib/history'
import NotFoundPage from './404'
import CarBodyPolygonDevelopment from './CarBodyPolygonDevelopment'
import CarEnvironmentPolygonDevelopment from './CarEnvironmentPolygonDevelopment'
import CarInteriorPolygonDevelopment from './CarInteriorPolygonDevelopment'
import HomePage from './home'
import InspectionPage from './Inspection'
import LoginPage from './login'
const loginUrl = `/login`

export const Routing: React.FC = () => {
  const history = useHistory()

  const authenticated = viewerModel.useAuthenticated()

  useEffect(() => {
    if (!authenticated) {
      pushFx(loginUrl)
    }
    // eslint-disable-next-line
  }, [authenticated])

  const switchRouteProps = useMemo(
    () =>
      !authenticated
        ? { location: { ...history.location, pathname: '/login' } }
        : {},
    [authenticated, history.location],
  )

  return (
    <IonRouterOutlet>
      <Switch {...switchRouteProps}>
        <Route path={loginUrl}>
          <MinimalLayout>
            <LoginPage />
          </MinimalLayout>
        </Route>
        <Route path='*'>
          <Switch>
            <Route path='/' exact component={HomePage} />
            <Route path='/inspection/:carId' component={InspectionPage} />
            {isDevEnv && (
              <Route
                path='/car-body-polygon-development'
                component={CarBodyPolygonDevelopment}
              />
            )}
            {isDevEnv && (
              <Route
                path='/car-interior-polygon-development'
                component={CarInteriorPolygonDevelopment}
              />
            )}
            {isDevEnv && (
              <Route
                path='/car-environment-polygon-development'
                component={CarEnvironmentPolygonDevelopment}
              />
            )}
            <Route path='*'>
              <NotFoundPage />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </IonRouterOutlet>
  )
}
