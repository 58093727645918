import { ComponentProps } from 'react'
import { Controller } from 'react-hook-form'
import { IonCheckbox } from './styled'

type CheckboxProps = {
  name: string
  label: string
} & ComponentProps<typeof IonCheckbox>

export function Checkbox({ label, name, ...checkboxProps }: CheckboxProps) {
  return (
    <Controller
      name={name}
      defaultValue={false}
      render={({ field: { onChange, value } }) => {
        return (
          <IonCheckbox
            checked={value}
            onIonChange={(e) => {
              onChange(e.detail.checked)
            }}
            {...checkboxProps}
            labelPlacement='end'
            justify='start'
            mode='md'
          >
            {label}
          </IonCheckbox>
        )
      }}
    />
  )
}
