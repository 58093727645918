import { IonButton as IonicIonButton } from '@ionic/react'
import { forwardRef, HTMLAttributes } from 'react'
import type { ComponentProps, ReactNode } from 'react'
import {
  ButtonContent,
  EndDecoratorWrapper,
  IonButton,
  IonSpinner,
  StartDecoratorWrapper,
} from './styled'

export type Variant =
  | 'limeFlooded'
  | 'crimsonOutline'
  | 'primaryOutline'
  | 'whiteBorder'
  | 'warningBorder'
  | 'text'
  | 'textBlue'
  | 'textCrimson'
  | 'textWhite'

type ButtonProps = ComponentProps<typeof IonicIonButton> & {
  variant?: Variant
  children: ReactNode
  startDecorator?: ReactNode
  endDecorator?: ReactNode
  loading?: boolean
  fullWidth?: boolean
  uppercase?: boolean
  contentProps?: HTMLAttributes<HTMLDivElement>
}

export const Button = forwardRef<HTMLIonButtonElement, ButtonProps>(
  (
    {
      variant = 'limeFlooded',
      children,
      startDecorator,
      endDecorator,
      loading,
      disabled,
      fullWidth,
      uppercase,
      contentProps,
      ...props
    },
    ref,
  ) => {
    return (
      <IonButton
        {...props}
        $variant={variant}
        ref={ref}
        disabled={disabled || loading}
        $fullWidth={fullWidth}
        $uppercase={uppercase}
      >
        <ButtonContent {...contentProps}>
          {startDecorator && (
            <StartDecoratorWrapper>
              {loading ? <IonSpinner name='bubbles' /> : startDecorator}
            </StartDecoratorWrapper>
          )}

          <div>{children}</div>

          {endDecorator && (
            <EndDecoratorWrapper>
              {loading ? <IonSpinner name='bubbles' /> : endDecorator}
            </EndDecoratorWrapper>
          )}

          {!endDecorator && !startDecorator && loading && (
            <EndDecoratorWrapper>
              <IonSpinner name='bubbles' />
            </EndDecoratorWrapper>
          )}
        </ButtonContent>
      </IonButton>
    )
  },
)

Button.displayName = 'Button'
