import { ReactNode } from 'react'
import { Wrapper } from './styled'

type Props = {
  children?: ReactNode
}

export function MinimalLayout({ children }: Props) {
  return <Wrapper>{children || null}</Wrapper>
}
