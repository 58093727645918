import { IonModal as IonicIonModal } from '@ionic/react'
import styled from 'styled-components'
import { BODY_MAX_WIDTH } from '~/shared/ui/theme'

export const IonModal = styled(IonicIonModal)`
  --height: fit-content;
  --border-radius: 4px;
  --width: calc(${BODY_MAX_WIDTH} - 32px);
`

export const Content = styled.div`
  padding: 16px 24px;
  overflow-x: hidden;
`

export const ButtonWrapper = styled.div<{
  $reverse?: boolean
}>`
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
  flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
`
