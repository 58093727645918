import { IonButton as IonicIonButton } from '@ionic/react'
import { styled } from 'styled-components'
import { myPalette } from '~/shared/ui/theme'
import { Variant } from './IconButton'

export const IonButton = styled(IonicIonButton)<{
  $variant: Variant
}>`
  min-height: auto;
  aspect-ratio: 1;

  &[disabled] {
    opacity: 0.6;
  }

  &::part(native) {
    padding: 0;
  }

  ${({ $variant }) => {
    if ($variant === 'outline') {
      return {
        '&::part(native)': {
          padding: 0,
        },
      }
    }
    if ($variant === 'fillWhite') {
      return {
        borderRadius: '100%',
        background: myPalette.white.main,
      }
    }
    if ($variant === 'blue') {
      return {
        borderRadius: '100%',
        background: myPalette.prussianBlue.main,
      }
    }
  }}
`
