import { IonSearchbar as IonicIonSearchbar } from '@ionic/react'
import { styled } from 'styled-components'

export const IonSearchbar = styled(IonicIonSearchbar)`
  && {
    --border-radius: 4px;
    padding: 0;
    font-size: 24px;
    margin-right: 16px;
  }
`
