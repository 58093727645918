import styled from 'styled-components'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled.div`
  background: ${myPalette.white.main};
  border: 1px solid ${myPalette.dark.jetBlack};
  border-radius: 4px;
  width: 100%;
  margin-bottom: 24px;

  canvas {
    width: 100%;
    height: 398px;
  }
`
