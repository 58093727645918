import { IonIcon } from '@ionic/react'
import * as Sentry from '@sentry/react'
import { warningOutline } from 'ionicons/icons'
import * as React from 'react'
import { Button } from '~/shared/ui/Buttons'
import { myPalette } from '~/shared/ui/theme'
import { Description, Title, Wrapper } from './styled'

type Props = {
  children: React.ReactNode
}

function ErrorFallback() {
  return (
    <Wrapper>
      <Title>На странице произошла ошибка</Title>
      <Description>
        Если после обновления страницы ошибка не проходит, обратитесь к
        администратору
      </Description>
      <Button
        variant='warningBorder'
        onClick={() => window.location.reload()}
        endDecorator={
          <IonIcon
            icon={warningOutline}
            style={{ color: myPalette.orange.main }}
          />
        }
      >
        Обновить
      </Button>
    </Wrapper>
  )
}

export function ErrorBoundary(props: Props) {
  const { children } = props
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  )
}
