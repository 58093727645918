import { ComponentProps } from 'react'
import { Controller } from 'react-hook-form'
import { Toggle } from './styled'

type SwitchProps = {
  name: string
  label: string
} & ComponentProps<typeof Toggle>

export function Switch({ label, name, ...toggleProps }: SwitchProps) {
  return (
    <Controller
      name={name}
      defaultValue={false}
      render={({ field: { onChange, value } }) => {
        return (
          <Toggle
            checked={value}
            onIonChange={(e) => {
              onChange(e.detail.checked)
            }}
            {...toggleProps}
            labelPlacement='end'
            justify='start'
            name={name}
          >
            {label}
          </Toggle>
        )
      }}
    />
  )
}
