import { Modal } from '~/shared/ui/Modal'
import { Description, Title } from './styled'

type RejectInspectionModal = {
  isShow: boolean
  onClose: () => void
  onOk: () => void
}

export function RejectInspectionModal({
  isShow,
  onClose,
  onOk,
}: RejectInspectionModal) {
  return (
    <Modal
      isShow={isShow}
      onCancel={onClose}
      onOk={onOk}
      cancel='Нет'
      ok='Да'
      canBackdropClose={false}
      reverseButton
    >
      <Title>Вы хотите прервать осмотр?</Title>
      <Description>
        После того как вы прервете осмотр данные не сохранятся
      </Description>
    </Modal>
  )
}
